import React, {useEffect} from "react";
import "../styles/Privacy.css";
import {Link} from "react-router-dom";

function Privacy() {
  useEffect(() => {
    document.title = "Privacy | DrClick";
  }, []);

  return (
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        <hr className="privacy-divider" />
        <div className="privacy-content">
          <p>This Privacy Policy outlines the methods by which the <strong>drclick.me</strong> platform collects, uses, and safeguards personal information. By engaging with the platform, you agree to the data practices described herein.</p>
          <p><strong>Note:</strong> By accepting the privacy policy, you also agree to the use of essential cookies necessary for the functionality and security of the platform. To accept optional cookies, you must do so through the cookie banner. Essential cookies are assumed to be accepted for using the website, while optional cookies require your explicit consent.</p>
          
          <h2>Identity and Contact Details</h2>
          <p><strong>Controller:</strong>Gilles Udin</p>
          <p><strong>Contact:</strong>Gilles_Udin@protonmail.ch</p>
          
          <h2>Collection of Personal Information</h2>
          <p>Personal information is collected to enhance user experience and deliver customized services. The information collected includes:</p>
          <ul>
            <li><strong>Voluntarily provided information: </strong>Information related to what the user has filled out in the various forms.</li>
            <li><strong>Automatically Collected Information: </strong>The platform may automatically collect information about your device and activity anonymously. This includes browser types, time zones, operating systems, last access, account status, and other device technologies. Google Analytics is configured to anonymize IP addresses and is used for analytics (It will collect all this information only if the user consents to the use of cookies). </li>
          </ul>

          <h2>Legal Basis for Processing</h2>
          <ul>
            <li><strong>Consent:</strong> For optional cookies and certain marketing activities</li>
            <li><strong>Contract:</strong> For the fulfillment of services provided through the platform.</li>
            <li><strong>Legal obligation:</strong> Compliance with legal obligations.</li>
            <li><strong>Legitimate Interests:</strong> To improve services and ensure platform security.</li>
          </ul>

          <h2>Use of Personal Information</h2>
          <p>The information collected is utilized to:</p>
          <ul>
            <li>Enhance and personalize user experiences on the platform.</li>
            <li>Analyze user behavior and preferences to improve services.</li>
          </ul>

          <h2>Cookies and Tracking Technologies</h2>
          <ul>
            <li><strong>Essential Cookies: </strong>Crucial for managing user consent preferences, and ensuring the security and integrity of the platform.</li>
          </ul>

          <h2>Optional Tracking:</h2>
          <ul>
            <li><strong>Google Analytics: </strong>Analyzes user interactions on the platform. This includes tracking the frequency of page visits and other management activities. Managed according to Google's own <a className="url-privacy" href="https://policies.google.com/privacy">Privacy Policy</a>.</li>
          </ul>

          <h2>Managing Cookies</h2>
          <p>Users can accept or decline optional cookies. Essential cookies, required for basic functionalities, cannot be declined as they are integral to our services. Users may adjust their browser settings to block cookies, which may impact platform functionality.</p>

          <h2>Cookies and Storage Used</h2>
          <p>Here is a list of cookies, session storage, and local storage items used on the platform, along with their purposes:</p>
          <div className="cookies-table-container">
            <table className="cookies-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Purpose</th>
                  <th>Duration</th>
                  <th>Optional/Essential</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to distinguish users anonymously.</td>
                  <td>2 years</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to distinguish users anonymously.</td>
                  <td>24 hours</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_gat</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to throttle request rate.</td>
                  <td>1 minute</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_ga_ID</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to persist session state anonymously.</td>
                  <td>2 years</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>cookieConsent</td>
                  <td>Local Storage</td>
                  <td>Stores the user's consent decision for cookies.</td>
                  <td>2 years or until changed</td>
                  <td>Essential</td>
                </tr>
                <tr>
                  <td>isGAInitialized</td>
                  <td>Local Storage</td>
                  <td>Tracks whether Google Analytics has been initialized to prevent multiple initializations.</td>
                  <td>2 years or until changed</td>
                  <td>Essential</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p><strong>Note:</strong> The duration of cookies created by Google Analytics is subject to change by Google at any time.</p> 

          <h2>Retention of Personal Information</h2>
          <p>Personal information is retained for as long as necessary to fulfill the purposes for which it was collected or as required by law. <strong>(I don't know how long you will need the information of the forms)</strong></p>

          <h2>User Rights</h2>
          <p> (Before it's recommended to inform if the users have the right to delete their data).As the data collected by Google Analytics is anonymous, it cannot be associated with a specific user, and therefore cannot be accessed, corrected, or deleted on a user-specific basis. However, users can control the collection of their data by managing their cookie preferences.</p>


          <h2>Updates to the Privacy Policy</h2>
          <p>This policy may be updated at any time. Significant changes will be communicated via a notice on the platform or by email. <strong>(Or Another platform)</strong></p>

          <h2>Contact</h2>
          <p><strong>You can put an email, the phone number or, if you prefer, you can create a contact page with more information. However, if you decide to put only an email, you can delete this section as your email will be in the "Identity and Contact Details" section</strong></p>
        </div>
      </div>
  );
}

export default Privacy;