import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function Conservative_General_LL({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({

    ClosedReduction:"",
    
    ImmobilisationType:"",
    ImmobilisationLenght:"",
    WeightBearingStatus:"",
   

    ImmobilisationType2:"",
    ImmobilisationLenght2:"",
    WeightBearingStatus2:"",
  

    Thromboprophylaxis:"",
    ThromboprophylaxisLenght:"",

    NumberofAppointments:"",
    Appointments1:"",
    Appointments2:"",
    Appointments3:"",
    Appointments4:"",
    Appointments5:"",

    WorkIncapacity:""


  });

  const [SecondImmob, setSecondImmob] = useState(false);

  const handleChangeSecondImmob = (event) => {
    setSecondImmob(event.target.checked);
  }

  const [Reduction, setReduction] = useState(false);

  const handleChangeReduction = (event) => {
    setReduction(event.target.checked);
  }

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Traitement conservateur général, membre inférieur");
    // Here is where you would prepare the object with the form details to send to the onFormSubmit function
    const formDetails = {
      "Type de traitement": "conservateur",
      "Réduction": formState.ClosedReduction,
      "Immobilisation":[formState.ImmobilisationType, (formState.ImmobilisationLenght ? `pour une durée de ${formState.ImmobilisationLenght} semaine(s)` : "")].filter(function(val) {
        return val;
      }).join(' ') + formState.WeightBearingStatus,

      "Puis relais immobilisation":[formState.ImmobilisationType2, (formState.ImmobilisationLenght2 ? `pour une durée de ${formState.ImmobilisationLenght2} semaine(s)` : "")].filter(function(val) {
        return val;
      }).join(' ')+ formState.WeightBearingStatus2,





      "Thromboprophylaxie":[formState.Thromboprophylaxis, (formState.ThromboprophylaxisLenght ? `pour une durée de ${formState.ThromboprophylaxisLenght} semaine(s)` : "")].filter(function(val) {
        return val;
      }).join(' '),
      
      "RDV de suivi à (semaines)": [formState.Appointments1, formState.Appointments2, formState.Appointments3, formState.Appointments4,formState.Appointments5 ].filter(function(val) {
        return val;
      }).join(', '),

      "Incapacité de travail estimée": (formState.WorkIncapacity ? `${formState.WorkIncapacity} semaine(s)` : "")
     
 

    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    onFormSubmit(filteredFormDetails); // Make sure this prop is passed correctly from App.js
  }

  return (
    <div className="card">
      <p className="title">Traitement conservateur, membre inférieur</p>
      <form className="specific-form" onSubmit={handleSubmit}>

        <div>

      <input
       type="checkbox"
       name="Reduction"
       onChange={handleChangeReduction}
       
     />
     <label>Réduction</label>
     {Reduction && (
        <>
        <div>
        
        <select className="select-input" name="ClosedReduction" value={formState.ClosedReduction} onChange={handleInputChange} >
          <option value="fermée">réduction fermée</option>
          <option value="fermée, sous sédation">réduction fermée sous sédation</option>
          <option value="fermée, sous anesthésie générale">réduction fermée sous anesthésie générale</option>

      
        </select>
        </div>
        </>
      )}
    

    </div>



        
      <div>
        <label>Immobilisation et durée {'('}semaines{')'}</label>
        <select className="select-input-1-3" name="ImmobilisationType" value={formState.ImmobilisationType} onChange={handleInputChange} >
          <option value="">Type:</option>
          <option value="aucune">aucun</option>
          <option value="attelle de cheville">attelle de cheville</option>
          <option value="attelle jambière postérieure">attelle jambière postérieure</option>
          <option value="botte de marche">botte de marche</option>
          <option value="attelle cruro-pédieuse">attelle cruro-pédieuse</option>
          <option value="plâtre cruro-pédieux">plâtre cruro-pédieux</option>
          <option value="attelle de genou en extension">attelle de genou en extension</option>
          <option value="attelle de genou articulée">attelle de genou articulée</option>
      
        </select>

        <select className="select-input-1-3" name="WeightBearingStatus" value={formState.WeightBearingStatus} onChange={handleInputChange} >
        <option value="">Marche:</option>
          <option value=" avec charge libre selon douleurs">charge libre selon douleurs</option>
          <option value=" avec charge partielle 15-20kg">charge partielle 15-20kg</option>
          <option value=" avec charge partielle 10-15kg">charge partielle 10-15kg</option>
          <option value=" avec déroulé du pas">déroulé du pas</option>
          <option value=" avec décharge stricte">décharge stricte</option>    
        </select>
    
      <input type="number" className="number-input" name="ImmobilisationLenght"  placeholder= "durée" value={formState.ImmobilisationLenght} onChange={handleInputChange} />

    
   
   <input
       type="checkbox"
       name="SecondImmob"
       onChange={handleChangeSecondImmob}
       
     />
     <label>Puis changement immobilisation</label>
    
   </div>
   

   {SecondImmob && (
       <div className="checkbox-options">
       
       <div>
        
        <select className="select-input-1-3" name="ImmobilisationType2" value={formState.ImmobilisationType2} onChange={handleInputChange} >
          <option value="">Type:</option>
          <option value="aucune">aucun</option>
          <option value="attelle de cheville">attelle de cheville</option>
          <option value="attelle jambière postérieure">attelle jambière postérieure</option>
          <option value="botte de marche">botte de marche</option>
          <option value="attelle cruro-pédieuse">attelle cruro-pédieuse</option>
          <option value="plâtre cruro-pédieux">plâtre cruro-pédieux</option>
          <option value="attelle de genou en extension">attelle de genou en extension</option>
          <option value="attelle de genou articulée">attelle de genou articulée</option>
      
        </select>

        <select className="select-input-1-3" name="WeightBearingStatus2" value={formState.WeightBearingStatus2} onChange={handleInputChange} >
        <option value="">Marche:</option>
          <option value=" avec charge libre selon douleurs">charge libre selon douleurs</option>
          <option value=" avec charge partielle 15-20kg">charge partielle 15-20kg</option>
          <option value=" avec charge partielle 10-15kg">charge partielle 10-15kg</option>
          <option value=" avec déroulé du pas">déroulé du pas</option>
          <option value=" avec décharge stricte">décharge stricte</option>    
        </select>
    
      <input type="number" className="number-input" name="ImmobilisationLenght2"  placeholder= "durée" value={formState.ImmobilisationLenght2} onChange={handleInputChange} />

      </div>

     </div>


   )}

     
      <div>
      <select className="select-input-1-3" name="Thromboprophylaxis" value={formState.Thromboprophylaxis} onChange={handleInputChange} >
        <option value="">Thromboprophylaxie:</option>
          <option value="aucune">aucune</option>
          <option value="Enoxaparine 40mg SC, 1x/J">Enoxaparine 40mg SC, 1x/J</option>
          <option value="Rivaroxaban 10mg 1x/J">Rivaroxaban 10mg 1x/J</option>
          <option value="AUTRE">AUTRE</option>   
        </select>

      <input type="number" className="number-input" name="ThromboprophylaxisLenght"  placeholder= "durée" value={formState.ThromboprophylaxisLenght} onChange={handleInputChange} />

      </div>

      <label>RDV de suivi:</label> <br></br>

      <input type="number" className="number-input" name="NumberofAppointments"  max="5" placeholder= "nombre" value={formState.NumberofAppointments} onChange={handleInputChange} />


      <div>
  

      {formState.NumberofAppointments === "1" && (
        <>
        <label>Délai 1er RDV: </label>
        <input type="number" className="number-input" name="Appointments1"  placeholder= "semaine" value={formState.Appointments1} onChange={handleInputChange} />

        </>
      )}

      {formState.NumberofAppointments === "2" && (
        <>
        <label>Délai 1er RDV:</label> &ensp;
        <input type="number" className="number-input" name="Appointments1"  placeholder= "semaine" value={formState.Appointments1} onChange={handleInputChange} />

        <br></br>

        <label>Délai 2ème RDV:</label>
        <input type="number" className="number-input" name="Appointments2"  placeholder= "semaine" value={formState.Appointments2} onChange={handleInputChange} />
        </>
      )}  

      {formState.NumberofAppointments === "3" && (
        <>
        <label>Délai 1er RDV:</label> &ensp;
        <input type="number" className="number-input" name="Appointments1"  placeholder= "semaine" value={formState.Appointments1} onChange={handleInputChange} />

        <br></br>

        <label>Délai 2ème RDV:</label>
        <input type="number" className="number-input" name="Appointments2"  placeholder= "semaine" value={formState.Appointments2} onChange={handleInputChange} />

        <br></br>

        <label>Délai 3ème RDV:</label>
        <input type="number" className="number-input" name="Appointments3"  placeholder= "semaine" value={formState.Appointments3} onChange={handleInputChange} />
        </>
      )}  

      {formState.NumberofAppointments === "4" && (
        <>
        <label>Délai 1er RDV:</label> &ensp;
        <input type="number" className="number-input" name="Appointments1"  placeholder= "semaine" value={formState.Appointments1} onChange={handleInputChange} />

        <br></br>

        <label>Délai 2ème RDV:</label>
        <input type="number" className="number-input" name="Appointments2"  placeholder= "semaine" value={formState.Appointments2} onChange={handleInputChange} />

        <br></br>

        <label>Délai 3ème RDV:</label>
        <input type="number" className="number-input" name="Appointments3"  placeholder= "semaine" value={formState.Appointments3} onChange={handleInputChange} />

        <br></br>

        <label>Délai 4ème RDV:</label>
        <input type="number" className="number-input" name="Appointments4"  placeholder= "semaine" value={formState.Appointments4} onChange={handleInputChange} />
        </>
      )}  

{formState.NumberofAppointments === "5" && (
        <>
        <label>Délai 1er RDV:</label> &ensp;
        <input type="number" className="number-input" name="Appointments1"  placeholder= "semaine" value={formState.Appointments1} onChange={handleInputChange} />

        <br></br>

        <label>Délai 2ème RDV:</label>
        <input type="number" className="number-input" name="Appointments2"  placeholder= "semaine" value={formState.Appointments2} onChange={handleInputChange} />

        <br></br>

        <label>Délai 3ème RDV:</label>
        <input type="number" className="number-input" name="Appointments3"  placeholder= "semaine" value={formState.Appointments3} onChange={handleInputChange} />

        <br></br>

        <label>Délai 4ème RDV:</label>
        <input type="number" className="number-input" name="Appointments4"  placeholder= "semaine" value={formState.Appointments4} onChange={handleInputChange} />

        <br></br>

        <label>Délai 5ème RDV:</label>
        <input type="number" className="number-input" name="Appointments5"  placeholder= "semaine" value={formState.Appointments5} onChange={handleInputChange} />
        </>
        
      )}  

    </div>  

    <div>
    
    <label>Incapacité de travail estimée:</label><br></br>

    <input type="number" className="number-input" name="WorkIncapacity"  placeholder= "semaine" value={formState.WorkIncapacity} onChange={handleInputChange} />

    </div>

    
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Conservative_General_LL;