import React, { useEffect, useContext  } from 'react';
import "../styles/cookies.css";
import "../styles/app.css";
import { Link } from "react-router-dom";

const CookieConsentBanner = ({ setCookieConsent, isVisibleCookieBanner, setIsVisibleCookieBanner }) => {

    useEffect(() => {
        const storedConsent = localStorage.getItem('cookieConsent');
        setCookieConsent(storedConsent === 'true'); // Set state based on storage

        if(storedConsent === null){
            setIsVisibleCookieBanner(true); // Show banner if no consent yet
        }
    }, [setCookieConsent, setIsVisibleCookieBanner]);

    const handleConsent = (consents) => {
        localStorage.setItem('cookieConsent', consents); // Store consent decision
        setCookieConsent(consents); // Update state
        setIsVisibleCookieBanner(false); // Hide banner once decided
        window.dispatchEvent(new Event('storage'));
    };

    if (isVisibleCookieBanner) { // Display banner if consent isn't given
        return (
            <div className="cookie-banner">
                <p>This site uses cookies to enhance performance and analyze site usage. By clicking accept, you agree to the use of analytics cookies. For more information, please visit <Link to="/privacy" className="url-privacy">Privacy Policy</Link>.</p>
                <button onClick={() => handleConsent(true)}>Accept</button>
                <button onClick={() => handleConsent(false)}>Decline</button>
            </div>
        );
    }

    return null; // Hide banner once decided
};

export default CookieConsentBanner;