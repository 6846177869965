import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";
import Modal from "../../../../other-components/Modal.js";

function Imaging_Chondro({setNameForm, onFormSubmit, onSelectionChange}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    Exam_date_Day: '',
    Exam_date_Month: '',
    Exam_date_Year: '',

    Location_Bone: '',
    Location_Detail:'',
    Location_Axial:'',
    Side:'',

    TumorSize_CC:'',
    TumorSize_ML:'',
    TumorSize_AP:'',

    Endosteal_Scalloping_circumference:'',
    Endosteal_Scalloping_Lenght:'',
    Medullary_Expansion:'',
    Cortical_Break:'',
    Periosteal_Reaction:'',
    Pathological_Fracture:'',
    Cortical_Thickening:'',
    Marrow_Oedema:'',
    Soft_Tissue_Component:'',

    Comment:'* critère d aggressivité radiologique'

  });



  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);






  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Description tumeur chondroïde");
 

    // Prepare form details for summary
    const formDetails = {
      "Examen": [formState.xRayWorkUp, formState.Exam_date_Day, formState.Exam_date_Month, formState.Exam_date_Year]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      "Localisation": [formState.Location_Bone, formState.Location_Detail, formState.Location_Axial, formState.Side]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      "Présence d'une lésion chondroïde, dimensions": (formState.TumorSize_CC ? `${formState.TumorSize_CC} (mm, cranio-caudal) ` : "") + (formState.TumorSize_ML ? `${formState.TumorSize_ML} (mm, médio-lat) ` : "") + (formState.TumorSize_AP ? `${formState.TumorSize_AP} (mm, antéro-post) ` : ""),
      "Caractérisiques": [formState.Endosteal_Scalloping_circumference, formState.Endosteal_Scalloping_Lenght, formState.Medullary_Expansion, formState.Cortical_Break, formState.Periosteal_Reaction, formState.Pathological_Fracture, formState.Cortical_Thickening, formState.Marrow_Oedema, formState.Soft_Tissue_Component]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
    

      "Note": formState.Comment
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Description tumeur chondroïde</p>
      <form className="specific-form" onSubmit={handleSubmit}>


          
            <div>
            <select name="xRayWorkUp" className="select-input" value={formState.xRayWorkUp} onChange={handleInputChange} >
            <option value="">Examen:</option>
            <option value="CT-scan">CT</option>
            <option value="IRM">IRM</option>
            <option value="IRM avec contraste">IRM avec contraste</option>
            <option value="AUTRE">AUTRE</option>
            </select>
            </div>

            <div>
            
            <label>Réalisé{'('}e{')'} le:</label> <br></br>


            <select name="Exam_date_Day" className="select-input-1-3" value={formState.Exam_date_Day} onChange={handleInputChange}>
             <option value="">Jour:</option>
             <option value="1">1</option>
             <option value="2">2</option>
             <option value="3">3</option>
             <option value="4">4</option>
             <option value="5">5</option>
             <option value="6">6</option>
             <option value="7">7</option>
             <option value="8">8</option>
             <option value="9">9</option>
             <option value="10">10</option>
             <option value="11">11</option>
             <option value="12">12</option>
             <option value="13">13</option>
             <option value="14">14</option>
             <option value="15">15</option>
             <option value="16">16</option>
             <option value="17">17</option>
             <option value="18">18</option>
             <option value="19">19</option>
             <option value="20">20</option>
             <option value="21">21</option>
             <option value="22">22</option>
             <option value="23">23</option>
             <option value="24">24</option>
             <option value="25">25</option>
             <option value="26">26</option>
             <option value="27">27</option>
             <option value="28">28</option>
             <option value="29">29</option>
             <option value="30">30</option>
             <option value="31">31</option>
             </select>




            <select name="Exam_date_Month" className="select-input-1-3" value={formState.Exam_date_Month} onChange={handleInputChange}>
             <option value="">Mois:</option>
             <option value="janvier">janvier</option>
             <option value="février">février</option>
             <option value="mars">mars</option>
             <option value="avril">avril</option>
             <option value="mai">mai</option>
             <option value="juin">juin</option>
             <option value="juillet">juillet</option>
             <option value="aout">aout</option>
             <option value="septembre">septembre</option>
             <option value="octobre">octobre</option>
             <option value="novembre">novembre</option>
             <option value="décembre">décembre</option>
             </select>
             
             <select name="Exam_date_Year" className="select-input-1-3" value={formState.Exam_date_Year} onChange={handleInputChange}>
             <option value="">Année</option>
             <option value="2023">2023</option>
             <option value="2024">2024</option>
             <option value="2025">2025</option>
             </select>

             </div>
        



             <label>Localisation:</label>
             <div>
           
           <select name="Location_Bone" className="select-input-1-3" value={formState.Location_Bone} onChange={handleInputChange}>
            <option value=""></option>
            <option value="clavicule">clavicule</option>
            <option value="scapula">scapula</option>
            <option value="humérus">humérus</option>
            <option value="radius">radius</option>
            <option value="ulna">ulna</option>
            <option value="">---</option>
            <option value="ilium">ilium</option>
            <option value="ischium">ischium</option>
            <option value="pubis">pubis</option>
            <option value="">---</option>
            <option value="fémur">fémur</option>
            <option value="tibia">tibia</option>
            <option value="fibula">fibula</option>
            <option value="rotule">rotule</option>
      
          </select>

          <select name="Location_Detail" className="select-input-1-3" value={formState.Location_Detail} onChange={handleInputChange}>
            <option value=""></option>
            <option value="portion proximale">portion proximale</option>
            <option value="diaphysaire">portion diaphysaire</option>
            <option value="portion distale">portion distale</option>
            <option value="">---</option>
            <option value="épiphyse proximale">épiphyse proximale</option>
            <option value="jonction épiphyso-métaphysaire proximale">jonction épiphyso-métaphysaire proximale</option>
            <option value="diaphyse proximal">diaphyse proximal</option>
            <option value="médio-diaphysaire">médio-diaphysaire</option>
            <option value="diaphyse distale">diaphyse distale</option>
            <option value="jonction épiphyso-métaphysaire distale">jonction épiphyso-métaphysaire distale</option>
            <option value="épiphyse distale">épiphyse distale</option>

        
          </select>

          <select name="Location_Axial" className="select-input-1-3" value={formState.Location_Axial} onChange={handleInputChange}>
            <option value=""></option>
            <option value="intra-médullaire">intra-médullaire</option>
            <option value="endostée">endostée</option>
            <option value="intra-corticale">intra-corticale</option>
            <option value="périostéale">périostéale</option>
        
          </select>



          <div>
         
         <input type="radio" name="Side" value="côté gauche" checked={formState.Side === "côté gauche"} onChange={handleInputChange} />
         <label> gauche </label>
        
           <input type="radio" name="Side" value="côté droit" checked={formState.Side === "côté droit"} onChange={handleInputChange} />
           <label> droite </label>
       </div>

          <div></div>
          
         </div>




        <div>
        
        <div>
          <label>Dimension, C-C x M-L x A-P, mm:</label>
          <input type="number" className="number-input" name="TumorSize_CC" min="0"  placeholder="C-C" value={formState.TumorSize_CC} onChange={handleInputChange} />
          <input type="number" className="number-input" name="TumorSize_ML" min="0"  placeholder="M-L" value={formState.TumorSize_ML} onChange={handleInputChange} />
          <input type="number" className="number-input" name="TumorSize_AP" min="0"  placeholder="A-P" value={formState.TumorSize_AP} onChange={handleInputChange} />
        </div>




       
          <select name="Endosteal_Scalloping_circumference" className="select-input" value={formState.Endosteal_Scalloping_circumference} onChange={handleInputChange} >
            <option value="">"Endosteal scalloping", circonférence:</option>
            <option value="pas de scalloping endosteal plan axial">aucun</option>
            <option value="scalloping endosteal de 3 corticales sur 4 ou moins de 2/3 circonférence">moins de 3 corticales sur 4, ou moins de 2/3 circonférence</option>
            <option value="scalloping endosteal de 3 corticales sur 4 ou plus de 2/3 circonférence*" onSelect={formState.Comment="*caractéristique d'agressivité radiologique"}>3 corticales sur 4, ou plus de 2/3 circonférence</option>
            </select>

          

            <select name="Endosteal_Scalloping_Lenght" className="select-input" value={formState.Endosteal_Scalloping_Lenght} onChange={handleInputChange} >
            <option value="">"Endosteal scalloping", longitudinal:</option>
            <option value="pas de scalloping endosteal plan longitudinal">aucun</option>
            <option value="scalloping endosteal longitudinal <2/3 longueur lésion">scalloping endosteal longitudinal, {'<'}2/3 longueur lésion</option>
            <option value="scalloping endosteal longitudinal >2/3 longueur lésion*" >scalloping endosteal longitudinal, {'>'}2/3 longueur lésion</option>
            </select>

            <select name="Medullary_Expansion" className="select-input" value={formState.Medullary_Expansion} onChange={handleInputChange} >
            <option value="">Expansion médullaire:</option>
            <option value="pas d'expansion médullaire ou de remodellement cortical">pas d'expansion médullaire ou de de remodellement cortical</option>
            <option value="expansion médullaire et remodellement cortical*">expansion médullaire et remodellement cortical</option>
            </select>
        

            <select name="Cortical_Break" className="select-input" value={formState.Cortical_Break} onChange={handleInputChange} >
            <option value="">Erosion/brèche corticale:</option>
            <option value="pas d'érosion corticale ou de brèche">pas d'érosion corticale ou de brèche</option>
             <option value="présence d'une érosion corticale ou brèche*">présence d'une érosion corticale ou brèche</option>
             </select>

            <select name="Periosteal_Reaction" className="select-input" value={formState.Periosteal_Reaction} onChange={handleInputChange} >
             <option value="">Réaction périostée:</option>
             <option value="pas de réaction périostée">aucune</option>
             <option value="présence d'une réaction périostée* (DETAIL)">présente</option>
             </select>

            <select name="Pathological_Fracture" className="select-input" value={formState.Pathological_Fracture} onChange={handleInputChange} >
           <option value="">Fracture pathologique:</option>
           <option value="pas de fracture pathologique">pas de frature pathologique</option>
           <option value="présence d'une fracture pathologique* (DETAIL)">présence d'une fracture pathologique</option>
           </select>


           <select name="Cortical_Thickening" className="select-input" value={formState.Cortical_Thickening} onChange={handleInputChange} >
           <option value="">Epaississement cortical:</option>
           <option value="absence d'épaississement cortical">absent</option>
           <option value="présence d'un épaississement cortical*">présent</option>
           </select>

           <select name="Marrow_Oedema" className="select-input" value={formState.Marrow_Oedema} onChange={handleInputChange} >
           <option value="">Oédème osseux:</option>
           <option value="absence d'oédème osseux">absent</option>
           <option value="présence d'un oédème osseux*">présent</option>
           </select>

           <select name=" Soft_Tissue_Component" className="select-input" value={formState.Soft_Tissue_Component} onChange={handleInputChange} >
           <option value="">Composante extra-osseuse:</option>
           <option value="absence de composante extra-osseuse">absente</option>
           <option value="présence d'une composante extra-osseuse*" >présente</option>
           </select>
        

        </div>
            
          
        
        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
     



      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default Imaging_Chondro;