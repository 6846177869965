import React, { useState, useEffect } from "react";
import "../../styles/app.css"; 
import InitialCard from "./upperLimb-initial-cards/InitialCard";
import Summary from "../../other-components/Summary";
import TextSummary from "../../other-components/TextSummary";

/*_____________________CHECKBOX CARDS_____________________*/
import CheckboxPhysicalExam from "./upperLimb-checkbox-cards/CheckboxPhysicalExam";
import CheckboxMedicalHistory from "./upperLimb-checkbox-cards/CheckboxMedicalHistory";
import CheckboxXRays from "./upperLimb-checkbox-cards/CheckboxXRays";
/*________________________________________________________*/

import Shoulder from "./upperLimb-specific-cards/physical-exam/Shoulder";
import Elbow from "./upperLimb-specific-cards/physical-exam/Elbow";
import NVstatusMS from "./upperLimb-specific-cards/physical-exam/NVstatusMS";
import Wrist from "./upperLimb-specific-cards/physical-exam/Wrist";

import MonoTraumaHistory from "./upperLimb-specific-cards/medical-history/MonoTraumaHistory";
import ChronicConditions from "./upperLimb-specific-cards/medical-history/ChronicConditions";
import InstabilityScore from "./upperLimb-specific-cards/medical-history/InstabilityScore";
import MedicalTreatment from "./upperLimb-specific-cards/medical-history/MedicalTreatment";


import HumerusXRayTraumat from "./upperLimb-specific-cards/x-rays/HumerusXRayTraumat";
import ShoulderXray from "./upperLimb-specific-cards/x-rays/ShoulderXray";
import ElbowForearmXRayTraumat from "./upperLimb-specific-cards/x-rays/ElbowForearmXRayTraumat";
import CollarBone from "./upperLimb-specific-cards/x-rays/CollarBone";

/*______________________INITIAL CARDS______________________*/
const description1 = "Anamnèses orthopédie et traumatologie, membre supérieur";
const description2 = "Examens cliniques standardisés, membre supérieur";
const description3 = "Bilan radiologique orthopédie et traumatologie, membre supérieur";

const name1 = "Anamnèse";
const name2 = "Examen clinique";
const name3 = "Radiologie";

const image1 = "../medical-history.png";
const image2 = "../physical-exam.png";
const image3 = "../x-rays.png";
/*_______________________________________________________________________________________________*/

function UpperLimb() {
  const [formDetails, setFormDetails] = useState(null);
  const [nameForm, setNameForm] = useState("");
  const [isFormSended, setIsFormSended] = useState(false);
  const [isText, setIsText] = useState(false);
/*_______________________________________________________________________________________________*/
  /*PHYSICAL EXAM BOX*/

  const [isVisibleCheckboxPhysical, setIsVisibleCheckboxPhysical] = useState(false);
  const [showSpecificPhysicalForm, setShowSpecificPhysicalForm] = useState(false);
  const [isVerticalMode, setIsVerticalMode] = useState(false);

  const [selectedPhysicalOptions, setSelectedPhysicalOptions] = useState({
    Epaule: false,
    Coude: false,
    Poignet: false,
    "Status neuro-vasculaire": false
   
  });

  ////////////////////////////////////////////////////////////

  // States for selections in third-cards
  const [selectedThirdCardsOptions, setSelectedThirdCardsOptions] = useState({
    "Epaule": false,
    "Coude": false,
    "Poignet": false,
    "Status neuro-vasculaire": false,
    "Anamnèse mono-traumatisme": false,
    "Instability Severity Score, épaule": false,
    "Co-morbidités - en construction": false,
    "Traitement en cours - en construction": false,
    "Humérus": false,
    "Clavicule et AC": false,
    "Coude, avant-bras et poignet": false,
    "Epaule-Xray": false,
    "Clavicule-Xray": false,
    "Humerus-Xray": false,
  });

  // Count the sections in third-cards
  const countThirdCardsSelections = () => {
    return Object.values(selectedThirdCardsOptions).filter(Boolean).length;
  };  

    // Check if vertical mode should be activated.
  useEffect(() => {
    const handleResize = () => {
      const totalThirdCardsSelections = countThirdCardsSelections();
      const isWindowWideEnough = window.innerWidth > 940;

      setIsVerticalMode(totalThirdCardsSelections >= 4 && isWindowWideEnough);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Execute immediately after mounting
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedThirdCardsOptions]);

  // To reflect the changes in the selections
useEffect(() => {
  const totalThirdCardsSelections = countThirdCardsSelections();
  const isWindowWideEnough = window.innerWidth > 940;

  setIsVerticalMode(totalThirdCardsSelections >= 4 && isWindowWideEnough);
}, [selectedThirdCardsOptions]); // Only depends of selectedThirdCardsOptions

  // Function to reflect the changes in third-cards
  const handleSelectedThirdCardsChange = (optionKey, isSelected) => {
    setSelectedThirdCardsOptions(prevState => {
      // Only update if the current value is different to the previous one. (True --> False) // (False --> True)
      if (prevState[optionKey] !== isSelected) {
        return {
          ...prevState,
          [optionKey]: isSelected
        };
      }
      return prevState;
    });
  };

  ////////////////////////////////////////////////////////////

  const handleSelectedPhysicalChange = (options) => {
    setSelectedPhysicalOptions(options);
  };

  const toggleVisiblePhysicalForm = () => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    setShowSpecificPhysicalForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxPhysical) {
      setSelectedPhysicalOptions({
        Epaule: false,
        Coude: false,
        Poignet: false,
    "Status neuro-vasculaire": false
      });
    }
  }, [isVisibleCheckboxPhysical]);

  useEffect(() => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificPhysicalForm(false);
    }
  }, [selectedPhysicalOptions]);

/*_______________________________________________________________________________________________*/
  /*MEDICAL HISTORY*/

  const [isVisibleCheckboxHistory, setIsVisibleCheckboxHistory] = useState(false);
  const [showSpecificHistoryForm, setShowSpecificHistoryForm] = useState(false);
  const [selectedHistoryOptions, setSelectedHistoryOptions] = useState({
    "Anamnèse mono-traumatisme": false,
    "Instability Severity Score, épaule": false,
    "Co-morbidités - en construction": false,
    "Traitement en cours - en construction": false,
  });

  const handleSelectedHistoryChange = (options) => {
    setSelectedHistoryOptions(options);
  };

  const toggleVisibleHistoryForm = () => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    setShowSpecificHistoryForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxHistory) {
      setSelectedHistoryOptions({
        "Anamnèse mono-traumatisme": false,
        "Instability Severity Score, épaule": false,
        "Co-morbidités - en construction": false,
        "Traitement en cours - en construction": false,
      });
    }
  }, [isVisibleCheckboxHistory]);

  useEffect(() => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificHistoryForm(false);
    }
  }, [selectedHistoryOptions]);

/*_______________________________________________________________________________________________*/
  /*X-RAYS*/

  const [isVisibleCheckboxXRays, setIsVisibleCheckboxXRays] = useState(false);
  const [showSpecificXRaysForm, setShowSpecificXRaysForm] = useState(false);
  const [selectedXRaysyOptions, setSelectedXRaysOptions] = useState({
    "Epaule": false,
    "Clavicule et AC": false,
    "Humérus": false,
    "Coude, avant-bras et poignet": false
   
  });

  const handleSelectedXRaysChange = (options) => {
    setSelectedXRaysOptions(options);
  };

  const toggleVisibleXRaysForm = () => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    setShowSpecificXRaysForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxXRays) {
      setSelectedXRaysOptions({
        "Epaule": false,
        "Clavicule et AC": false,
        "Humérus": false,
        "Coude, avant-bras et poignet": false,
      });
    }
  }, [isVisibleCheckboxXRays]);

  useEffect(() => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificXRaysForm(false);
    }
  }, [selectedXRaysyOptions]);

/*_______________________________________________________________________________________________*/

  const handleFormSubmit = (details, name, text) => {
    setFormDetails(details);
    setNameForm(name);
    setIsFormSended(true);
    setIsText(text);
  };

  return (
    <>
      {!isFormSended && (
        <div className={`container ${isVerticalMode ? 'vertical-mode' : ''}`}>
          <div className="first-cards">
            <InitialCard
              name={name1}
              description={description1}
              image={image1}
              visible={isVisibleCheckboxHistory}
              setIsVisible={setIsVisibleCheckboxHistory}
            />
            <InitialCard
              name={name2}
              description={description2}
              image={image2}
              visible={isVisibleCheckboxPhysical}
              setIsVisible={setIsVisibleCheckboxPhysical}
            />
            <InitialCard
              name={name3}
              description={description3}
              image={image3}
              visible={isVisibleCheckboxXRays}
              setIsVisible={setIsVisibleCheckboxXRays}
            />
          </div>

          <div className="second-cards">
            {isVisibleCheckboxHistory && (
              <CheckboxMedicalHistory
                selectedOptions={selectedHistoryOptions}
                onSelectedOptionsChange={handleSelectedHistoryChange}
                handleButtonClick={toggleVisibleHistoryForm}
                showSpecificForm={showSpecificHistoryForm}
              />
            )}

            {isVisibleCheckboxPhysical && (
              <CheckboxPhysicalExam
                selectedOptions={selectedPhysicalOptions}
                onSelectedOptionsChange={handleSelectedPhysicalChange}
                handleButtonClick={toggleVisiblePhysicalForm}
                showSpecificForm={showSpecificPhysicalForm}
              />
            )}
            {isVisibleCheckboxXRays && (
              <CheckboxXRays
                selectedOptions={selectedXRaysyOptions}
                onSelectedOptionsChange={handleSelectedXRaysChange}
                handleButtonClick={toggleVisibleXRaysForm}
                showSpecificForm={showSpecificXRaysForm}
              />
            )}
          </div>

          <div className="third-cards">
            {showSpecificHistoryForm && (
              <>
                {selectedHistoryOptions["Anamnèse mono-traumatisme"] && (
                  <MonoTraumaHistory
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Anamnèse mono-trauma", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Anamnèse mono-traumatisme", isSelected)}
                  />
                )}
                
                {selectedHistoryOptions["Instability Severity Score, épaule"] && (
                  <InstabilityScore
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Instability Severity Score", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Instability Severity Score, épaule", isSelected)}
                  />
                )}

                {selectedHistoryOptions["Co-morbidités - en construction"] && (
                  <ChronicConditions
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Co-morbidités", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Co-morbidités - en construction", isSelected)}
                  />
                )}
                
             
                {selectedHistoryOptions["Traitement en cours - en construction"] && (
                  <MedicalTreatment
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Traitement en cours", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Traitement en cours - en construction", isSelected)}
                    
                  />
                )}
              </>
            )}

            {showSpecificPhysicalForm && (
              <>
              
                {selectedPhysicalOptions.Epaule && (
                  <Shoulder
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Epaule", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Epaule", isSelected)}
                    
                  />
                )}
                {selectedPhysicalOptions.Coude && (
                  <Elbow
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Coude", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Coude", isSelected)}
                  />
                )}

                {selectedPhysicalOptions.Poignet && (
                  <Wrist
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Cheville", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Poignet", isSelected)}
                  />
                )}
                {selectedPhysicalOptions["Status neuro-vasculaire"] && (
                  <NVstatusMS
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Neuro-vasc", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Status neuro-vasculaire", isSelected)}
                  />
                )}
                
              </>
            )}

            {showSpecificXRaysForm && (
              <>
                {selectedXRaysyOptions.Epaule && (
                  <ShoulderXray
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: épaule", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Epaule-Xray", isSelected)}
                  />
                )}

            {selectedXRaysyOptions["Clavicule et AC"] && (
                  <CollarBone
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: clavicule", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Clavicule et AC", isSelected)}
                  />
                )}

                {selectedXRaysyOptions.Humérus && (
                  <HumerusXRayTraumat
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: humérus", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Humerus-Xray", isSelected)}
                  />
                )}
                {selectedXRaysyOptions["Coude, avant-bras et poignet"]&& (
                  <ElbowForearmXRayTraumat
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: coude et avant-bras", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Coude, avant-bras et poignet", isSelected)}
                  />
                )}

            
               
              </>
            )}
          </div>
        </div>
      )}

      {isFormSended && (
        isText ? (
          <TextSummary setIsFormSended={setIsFormSended} formDetails={formDetails} />
        ) : (
          <Summary nameSpecificCard={nameForm} setIsFormSended={setIsFormSended} formDetails={formDetails} />
        )
      )}
    </>
  );
}

export default UpperLimb;