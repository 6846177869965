import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";

const OrthopedicsHome = () => {
    return (
      <div className="home-container">
        <h1 className="home-title">Orthopédie et traumatologie</h1>
        <p className="home-intro">
           Intro text
        </p>
        <div className="home-cards-grid">
          <div className="home-card initial">
            <Link to="/orthopedics/upper-limb">
              <img className="initial-image" src="/upper-limb.webp" alt="Upper Limb" />
              <p className="home-card-title">Membre supérieur</p>
            </Link>
          </div>
          <div className="home-card initial">
            <Link to="/orthopedics/lower-limb">
              <img className="initial-image" src="/lower-limb.webp" alt="Lower Limb" />
              <p className="home-card-title">Membre inférieur</p>
            </Link>
          </div>   
          
        </div>
      </div>
    );
};
  
export default OrthopedicsHome;