import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function Ankle({setNameForm, onFormSubmit, onSelectionChange}) { 
  const [formState, setFormState] = useState({
    sideAnkle: "",
    ankleSkin: "pas de plaie, pas de cicatrice",
    ankleScar: "",
    ankleSwelling: "",
    ankleFlexion: "",
    ankleNeutral: "",
    ankleExtension: "",
    squeezeTestAnkle: "",
    thompsonTestAnkle: "",
    apStabilityAnkle: "",
    lateralStabilityAnkle: "",

  });

  const defaultValues = {
    ankleFlexion: 30,
    ankleNeutral: 0,
    ankleExtension: 15
  };

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const [anklePalpation, setAnklePalpation] = useState({
  
    "péroné distal": false,
    "Région inframalléolaire externe": false,
    "trajet LTFA": false,
    "malléole interne": false,
    "Région inframalléolaire interne": false,
    "Trajet fibulaires": false,
    "Tendon d’achille": false,
    "Tête 5ème métatarsien": false,
    "AUTRE": false
  
  });


  const handleCheckboxChangeAnklePalpation = (event) => {
    const { name, checked } = event.target;
     
      setAnklePalpation(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });


  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Examen clinique cheville");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const anklePalpationText = formatCheckboxStateToString(anklePalpation);
  const TNVbasicText= formatCheckboxStateToString(TNVbasic);


    // Prepare form details for summary
    const formDetails = {
      "Examen clinique cheville": formState.sideAnkle,
      "Etat cutané": [ formState.ankleSkin, formState.ankleScar]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),


      "Status N-V": TNVbasicText,
      "Tuméfaction": formState.ankleSwelling,
      "Site(s) douloureux à la palpation": anklePalpationText,

      "Flexion/Extension": [formState.ankleFlexion, formState.ankleNeutral, formState.ankleExtension]
        .filter((val) => val !== "" && val !== null && val !== undefined)
        .join('/'),


      "Squeeze test": formState.squeezeTestAnkle,
      "Thompson Test": formState.thompsonTestAnkle,
      "Stabilité antéro-postérieure": formState.apStabilityAnkle,
      "Stabilité latérale": formState.lateralStabilityAnkle
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details


   
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };


  return (
    <div className="card">
      <p className="title">Examen clinique cheville</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
          <label>Côté:  </label>
          <input type="radio" name="sideAnkle" value="gauche" checked={formState.sideAnkle === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="sideAnkle" value="droit" checked={formState.sideAnkle === "droit"} onChange={handleInputChange} />
            <label> droit </label>
        </div>

        <div> 

        <label>Etat cutané:</label>
          <select name="ankleSkin" className="select-input" value={formState.ankleSkin} onChange={handleInputChange} >
            <option value="pas de plaie, pas de cicatrice">Pas de plaie, pas de cicatrice</option>
            <option value="cicatrices d'abord latéral">Cicactrice d'abord latéral</option>
            <option value="cicatrice d'abord médial">cicatrice d'abord médial</option>
            <option value="cicatrice d'abord antérieur">cicatrice d'abord antérieur</option>
            <option value="AUTRE">AUTRE</option>
          </select>

        </div>

        {formState.ankleSkin !== "pas de plaie, pas de cicatrice" && (
           <div>
             <select name="ankleScar" className="select-input" value={formState.ankleScar} onChange={handleInputChange} >
            <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
            <option value="inflammatoire">inflammatoire</option>
            <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
            <option value="chéloïde">chéloïde</option>
            <option value="AUTRE">AUTRE</option>
          </select>
         
         </div>
      )
    }



        <div className="checkbox-options">
                
                <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
                <label>absence de trouble neuro-vasculaire</label>
              
          </div>



      

        <div className="checkbox-options">
        <p className="title-label-within-card">Douleurs à la palpation:</p>
        {Object.entries(anklePalpation).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeAnklePalpation} />
            <label>{key}</label>
          </div>
        ))}
      </div>

      <br></br>

      <div>
        
      
        <label className="title-label-within-card">Amplitudes articulaire: <br></br></label>
          <br></br>
          <div>
           <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
           </div>
           
        </div> 

      <div>
          <label>Flexion/extension:</label><br></br>
          <input type="number" className="number-input" name="ankleFlexion" min="0" max="150" value={formState.ankleFlexion} onChange={handleInputChange} />
        
          <input type="number" className="number-input" name="ankleNeutral" min="0" max="150" value={formState.ankleNeutral} onChange={handleInputChange} />
       
          <input type="number" className="number-input" name="ankleExtension" min="-30" max="150" value={formState.ankleExtension} onChange={handleInputChange} />
        </div>


        <div> 
          <p>Test de Thompson: </p>
          <div>
            <input type="radio" name="thompsonTestAnkle" value="négatif" checked={formState.thompsonTestAnkle === "négatif"} onChange={handleInputChange}  />
            <label> négatif </label>
          
            <input type="radio" name="thompsonTestAnkle" value="positif" checked={formState.thompsonTestAnkle === "positif"} onChange={handleInputChange} />
            <label> positif </label>
          </div>
        </div>



      <div> 
          <p>Squeeze test: </p>
          <div>
            <input type="radio" name="squeezeTestAnkle" value="négatif" checked={formState.squeezeTestAnkle === "négatif"} onChange={handleInputChange}  />
            <label> négatif </label>
          
            <input type="radio" name="squeezeTestAnkle" value="positif" checked={formState.squeezeTestAnkle === "positif"} onChange={handleInputChange} />
            <label> positif </label>
          </div>
        </div>
      




      <div>
          
          <select name="apStabilityAnkle" className="select-input" value={formState.apStabilityAnkle} onChange={handleInputChange} >
            <option value="">Stabilité antéro-postérieure:</option>
            <option value="stable, tiroir physiologique similaire au controlatéral">stable, tiroir physiologique similaire au controlatéral</option>
            <option value="Instable à 30, stable en extension">Instable, tiroir pathologique augmenté par rapport au controlatéral</option>
            <option value="non testable">non testable</option>
            <option value="AUTRE">AUTRE</option>
          </select>
     
          <select name="lateralStabilityAnkle" className="select-input" value={formState.lateralStabilityAnkle} onChange={handleInputChange} >
            <option value="">Stabilité latérale:</option>
            <option value="stable, tiroir physiologique similaire au controlatéral">stable, 'talar tilt' physiologique similaire au controlatéral</option>
            <option value="Instable à 30, stable en extension">Instable, 'talar tilt' pathologique augmenté par rapport au controlatéral</option>
            <option value="non testable">non testable</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        </div>




        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Ankle;