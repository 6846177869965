import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";

const Orthopedic_Oncology_Home = () => {
    return (
      <div className="home-container">
        <h1 className="home-title">Orthopédie oncologique</h1>
        <p className="home-intro">
           <label>text intro</label>
        </p>
        <div className="home-cards-grid">
          <div className="home-card initial">
            <Link to="/Orthopedic_Oncology/upper-limb">
              <img className="initial-image" src="/medical-history.png" alt="guidelines" />
              <p className="home-card-title">Guidelines: en construction</p>
            </Link>
          </div>
          <div className="home-card initial">
            <Link to="/Orthopedic_Oncology/OO_Clinical_forms">
              <img className="initial-image" src="/Orthopaedic_Oncology.webp" alt="Orthopaedic Oncology" />
              <p className="home-card-title">Formulaires cliniques</p>
            </Link>
          </div>   
          
        </div>
      </div>
    );
};
  
export default Orthopedic_Oncology_Home;