import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";

const CardiologyHome = () => {
    return (
      <div className="home-container">
        <h1 className="home-title">Compte-rendu opératoire, en construction</h1>
      </div>
    );
};
  
export default CardiologyHome;