import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

const AnalyticsInitializer = ({ consent, isGAInitialized, setIsGAInitialized }) => {

  const analyticsId = "G-VWQS9L7157";
  const deleteCookies = () => {
    
    const cookiesToDelete = [
      '_ga',
      '_gid',
      '_gat',
      `_ga_${analyticsId}`
    ];

    cookiesToDelete.forEach(cookie => {
      document.cookie = `${cookie}=; Max-Age=0; path=/; domain=.drclick.me`;
    });
  };

  const verifyAndDeleteCookies = () => {
    const cookiesToCheck = ['_ga', '_gid', '_gat', `_ga_${analyticsId}`];
    const maxAttempts = 5;
    let attempts = 0;

    const checkCookies = () => {
      const remainingCookies = cookiesToCheck.filter(cookieName => {
        return document.cookie.split(";").some(cookie => cookie.trim().startsWith(cookieName));
      });

      if (remainingCookies.length === 0 || attempts >= maxAttempts) {
        return;
      }

      deleteCookies();
      attempts++;
      setTimeout(checkCookies, 500);
    };

    checkCookies();
  };

  useEffect(() => {
    const initializeAnalytics = () => {
      if (consent !== null) {
        if (consent) {
          if (!isGAInitialized) {

            ReactGA.initialize(analyticsId, {
              gaOptions: {
                anonymizeIp: true,
                transport: 'beacon'
              }
            });
            setIsGAInitialized(true);

          }
          window[`ga-disable-${analyticsId}`] = false;
        } else {
          window[`ga-disable-${analyticsId}`] = true;
          verifyAndDeleteCookies();

        }
      }
    };

    initializeAnalytics();
  }, [consent, isGAInitialized, setIsGAInitialized]);

  return null;
};

export default AnalyticsInitializer;