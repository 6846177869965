import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";
import Modal from "../../../../other-components/Modal.js";

function Imaging_Lipo({setNameForm, onFormSubmit, onSelectionChange}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    Exam_date_Day: '',
    Exam_date_Month: '',
    Exam_date_Year: '',
    TumorSize_CC:'',
    TumorSize_ML:'',
    TumorSize_AP:'',

    Tumor_Depth:'',
    Tumor_Shape:'',
    Tumor_enhancement_Pattern: '',
    Tumor_intermingled_Muscle:'',
    Tumor_Septa:'',

    Comment:'*caractéristique pouvant être associée à une tumeur lipomateuse atypique'

  });



  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);






  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Description tumeur lipomateuse");
 

    // Prepare form details for summary
    const formDetails = {
      "Examen": formState.xRayWorkUp + " " + formState.Exam_date_Day + " " + formState.Exam_date_Month + " " + formState.Exam_date_Year,
      "Localisation": "A PRECISER EN TEXTE LIBRE",
      "Présence d'une masse lipomateuse, dimensions": (formState.TumorSize_CC ? `${formState.TumorSize_CC} (mm, cranio-caudal) ` : "") + (formState.TumorSize_ML ? `${formState.TumorSize_ML} (mm, médio-lat) ` : "") + (formState.TumorSize_AP ? `${formState.TumorSize_AP} (mm, antéro-post) ` : ""),
      "Caractérisiques": [formState.Tumor_Depth, formState.Tumor_Shape, formState.Tumor_intermingled_Muscle, formState.Tumor_Septa]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      "Séquences Gadolinium": formState.Tumor_enhancement_Pattern,

      "Note": formState.Comment
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };





  return (
    <div className="card">
      <p className="title">Description tumeur lipomateuse</p>
      <form className="specific-form" onSubmit={handleSubmit}>


          
            <div>
            <select name="xRayWorkUp" className="select-input" value={formState.xRayWorkUp} onChange={handleInputChange} >
            <option value="">Examen:</option>
            <option value="IRM">IRM</option>
            <option value="IRM avec contraste">IRM avec contraste</option>
            <option value="AUTRE">AUTRE</option>
            </select>
            </div>

            <div>
            
            <label>Réalisée le:</label> <br></br>


            <select name="Exam_date_Day" className="select-input-1-3" value={formState.Exam_date_Day} onChange={handleInputChange}>
             <option value="">Jour:</option>
             <option value="1">1</option>
             <option value="2">2</option>
             <option value="3">3</option>
             <option value="4">4</option>
             <option value="5">5</option>
             <option value="6">6</option>
             <option value="7">7</option>
             <option value="8">8</option>
             <option value="9">9</option>
             <option value="10">10</option>
             <option value="11">11</option>
             <option value="12">12</option>
             <option value="13">13</option>
             <option value="14">14</option>
             <option value="15">15</option>
             <option value="16">16</option>
             <option value="17">17</option>
             <option value="18">18</option>
             <option value="19">19</option>
             <option value="20">20</option>
             <option value="21">21</option>
             <option value="22">22</option>
             <option value="23">23</option>
             <option value="24">24</option>
             <option value="25">25</option>
             <option value="26">26</option>
             <option value="27">27</option>
             <option value="28">28</option>
             <option value="29">29</option>
             <option value="30">30</option>
             <option value="31">31</option>
             </select>




            <select name="Exam_date_Month" className="select-input-1-3" value={formState.Exam_date_Month} onChange={handleInputChange}>
             <option value="">Mois:</option>
             <option value="janvier">janvier</option>
             <option value="février">février</option>
             <option value="mars">mars</option>
             <option value="avril">avril</option>
             <option value="mai">mai</option>
             <option value="juin">juin</option>
             <option value="juillet">juillet</option>
             <option value="aout">aout</option>
             <option value="septembre">septembre</option>
             <option value="octobre">octobre</option>
             <option value="novembre">novembre</option>
             <option value="décembre">décembre</option>
             </select>
             
             <select name="Exam_date_Year" className="select-input-1-3" value={formState.Exam_date_Year} onChange={handleInputChange}>
             <option value="">Année</option>
             <option value="2023">2023</option>
             <option value="2024">2024</option>
             <option value="2025">2025</option>
             </select>

             </div>
        

        <div>
        
        <div>
          <label>Dimension, C-C x M-L x A-P, mm:</label>
          <input type="number" className="number-input" name="TumorSize_CC" min="0"  placeholder="C-C" value={formState.TumorSize_CC} onChange={handleInputChange} />
          <input type="number" className="number-input" name="TumorSize_ML" min="0"  placeholder="M-L" value={formState.TumorSize_ML} onChange={handleInputChange} />
          <input type="number" className="number-input" name="TumorSize_AP" min="0"  placeholder="A-P" value={formState.TumorSize_AP} onChange={handleInputChange} />
        </div>

       
          <select name="Tumor_Depth" className="select-input" value={formState.Tumor_Depth} onChange={handleInputChange} >
            <option value="">Profondeur:</option>
            <option value="localisation sous-cutanée">sous-cutanée</option>
            <option value="localisation superficielle">superficielle</option>
            <option value="localisation profonde*">profonde </option >

            </select>

          

      <select name="Tumor_Shape" className="select-input" value={formState.Tumor_Shape} onChange={handleInputChange} >
        <option value="">forme:</option>
        <option value="forme sphérique ou ovalaire">sphérique ou ovalaire</option>
        <option value="forme irrégulière*" >irrégulière</option>

        </select>
        

      <select name="Tumor_enhancement_Pattern" className="select-input" value={formState.Tumor_enhancement_Pattern} onChange={handleInputChange} >
        <option value="">Prise de contraste:</option>
        <option value="pas de prise de contraste">pas de prise de contraste</option>
        <option value="prise de contraste*">prise de contraste</option>

        </select>

        <select name="Tumor_intermingled_Muscle" className="select-input" value={formState.Tumor_intermingled_Muscle} onChange={handleInputChange} >
        <option value="">Contenu tumeur:</option>
        <option value="tissus adipeux homogène, pas de fibre musculaire, pas de région en hypersignal T2">tissus adipeux homogène, pas de fibre musculaire, pas de région en hypersignal T2</option>
        <option value="tissus hétérogène, présence de fibre musculaire ou région en hypersignal T2*" >tissus hétérogène, présence de fibre musculaire ou région en hypersignal T2</option>

        </select>

        <select name="Tumor_Septa" className="select-input" value={formState.Tumor_Septa} onChange={handleInputChange} >
        <option value="">Septa:</option>
        <option value="septas fins ou absents">absent ou fin</option>
        <option value="septas épaissis ou irréguliers*" >épaissis ou irréguliers</option>

        </select>
        

        </div>
            
          
      
        
        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
     

   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default Imaging_Lipo;