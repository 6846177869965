// Allergies.js
import React, { useState, useEffect } from 'react';
import '../../../../styles/app.css';

function IncidentalFinding({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({
    sex: '',
    age: '',
    LesionType: '',
    side:'',
    location_softTissue:'',
    location_softTissue_detail:'',
    location_Bone:'',
    location_Bone_detail:'',
    Exam_Type:'',
    Exam_Date_Month:'',
    Exam_Date_Year:'',
    Exam_Context:''
    
  });

 

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Traitement en cours');


    const formDetails = {

      'L1': formState.sex + " de " + formState.age + " ans adressé(e) à la suite de la découverte fortuite d'une " + formState.LesionType+ " au niveau " + formState.location_softTissue + " " + formState.location_softTissue_detail + formState.location_Bone + " " + formState.location_Bone_detail + ", " + formState.side + ".",
      'L2': "Cette dernière a été mise en évidence " + formState.Exam_Type + " réalisé(es) en " + formState.Exam_Date_Month + " " + formState.Exam_Date_Year + " " + formState.Exam_Context,
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  return (
    <div className="card">
      <p className="title">Découverte fortuite</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div className="radio-options">
          
            <input type="radio" name="sex" value="Patiente" checked={formState.sex=== "Patiente"} onChange={handleInputChange}/>
            <label>♀</label>
         
            <input type="radio" name="sex" value="Patient" checked={formState.sex === "Patient"} onChange={handleInputChange} />
            <label> ♂ </label>

            <input type="number" className="number-input" name="age" min="0" max="120" placeholder= "age" value={formState.age} onChange={handleInputChange} />

          
            </div>



        <div>
        <select name="LesionType" className="select-input" value={formState.LesionType} onChange={handleInputChange}>
            <option value="">Type lésion:</option>
            <option value="lésion des tissus mous">tissus mous</option>
            <option value="lésion osseuse">os</option>
      
          </select>
        </div>

  
      
        {formState.LesionType === "lésion osseuse" && (
           <div>
           <label>Localisation:</label>
           <select name="location_Bone" className="select-input" value={formState.location_Bone} onChange={handleInputChange}>
            <option value=""></option>
            <option value=" de la clavicule">clavicule</option>
            <option value="de la scapula">scapula</option>
            <option value="de l'humérus">humérus</option>
            <option value="du radius">radius</option>
            <option value="de l'ulna">ulna</option>
            <option value="">---</option>
            <option value="de l'ilium">ilium</option>
            <option value="de l'ischium">ischium</option>
            <option value="du pubis">pubis</option>
            <option value="">---</option>
            <option value="du fémur">fémur</option>
            <option value="du tibia">tibia</option>
            <option value="de la fibula">fibula</option>
            <option value="de la rotule">rotule</option>
      
          </select>

          <select name="location_Bone_detail" className="select-input" value={formState.location_Bone_detail} onChange={handleInputChange}>
            <option value=""></option>
            <option value="portion proximale">portion proximale</option>
            <option value="diaphysaire">portion diaphysaire</option>
            <option value="portion distale">portion distale</option>
        
          </select>
          <div>
         
         <input type="radio" name="side" value="côté gauche" checked={formState.side === "côté gauche"} onChange={handleInputChange} />
         <label> gauche </label>
        
           <input type="radio" name="side" value="côté droit" checked={formState.side === "côté droit"} onChange={handleInputChange} />
           <label> droite </label>
       </div>

          <div></div>
          
         </div>
            )
         }

          {formState.LesionType === "lésion des tissus mous" && (
           <div>
           <label>Localisation:</label>
           <select name="location_softTissue" className="select-input" value={formState.location_softTissue} onChange={handleInputChange}>
            <option value=""></option>
            <option value="de l'épaule">épaule</option>
            <option value="du bras">bras</option>
            <option value="du coude">coude</option>
            <option value="de l'avant-bras">avant-bras</option>
            <option value="du poignet">poignet</option>
            <option value="">---</option>
            <option value="de la région glutéale">région glutéale</option>
            <option value="de la cuisse">cuisse</option>
            <option value="du genou">genou</option>
            <option value="de la jambe">jambe</option>
            <option value="de la cheville">cheville</option>
            <option value="AUTRE">AUTRE</option>
          </select>

    
           <select name="location_softTissue_detail" className="select-input" value={formState.location_softTissue_detail} onChange={handleInputChange}>
            <option value=""></option>
            <option value="face antérieure">face antérieure</option>
            <option value="face latérale">face latérale</option>
            <option value="face postérieure">face postérieure</option>
            <option value="face médiale">face médiale</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <div>
         
         <input type="radio" name="side" value="côté gauche" checked={formState.side === "côté gauche"} onChange={handleInputChange} />
         <label> gauche </label>
        
           <input type="radio" name="side" value="côté droit" checked={formState.side === "côté droit"} onChange={handleInputChange} />
           <label> droite </label>
       </div>

      
         </div>
            )
         }
        
        <label>Examen réalisé:</label> <br></br>

           <select name="Exam_Type" className="select-input-1-3" value={formState.Exam_Type} onChange={handleInputChange}>
            <option value=""></option>
            <option value="sur des radiographies standards">radiographies standards</option>
            <option value="sur un CT-scan">CT-scan</option>
            <option value="sur une IRM">IRM</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="Exam_Date_Month" className="select-input-1-3" value={formState.Exam_Date_Month} onChange={handleInputChange}>
             <option value="">Mois:</option>
             <option value="janvier">janvier</option>
             <option value="février">février</option>
             <option value="mars">mars</option>
             <option value="avril">avril</option>
             <option value="mai">mai</option>
             <option value="juin">juin</option>
             <option value="juillet">juillet</option>
             <option value="aout">aout</option>
             <option value="septembre">septembre</option>
             <option value="octobre">octobre</option>
             <option value="novembre">novembre</option>
             <option value="décembre">décembre</option>
             </select>
             
             <select name="Exam_Date_Year" className="select-input-1-3" value={formState.Exam_Date_Year} onChange={handleInputChange}>
             <option value="">Année</option>
             <option value="2023">2023</option>
             <option value="2024">2024</option>
             <option value="2025">2025</option>
             </select>
     
    <div>
    <label>Contexte examen:</label>
           <select name="Exam_Context" className="select-input" value={formState.Exam_Context} onChange={handleInputChange}>
            <option value=""></option>
            <option value="dans les suites d'un traumatisme (DETAILS)">traumatique</option>
            <option value="dans le contexte d'une surveillance (DETAILS)">surveillance</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        
          </div>

        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default IncidentalFinding;