import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function ClinicalExamMass({setNameForm, onFormSubmit, onSelectionChange}) { 
  const [formState, setFormState] = useState({
    Location: "",
    Location_side:"",
    Location_Cranio_Caud: "",
    Location_axial: "",
    SkinChanges: "",
    PalpationSize: "",
    PalpationDepth:"",
    PalpationMob:"",
    PalpationPain: "",
    Adenopathy:""

  });

 

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  


  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });


  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Masse indéterminée, examen clinique");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const TNVbasicText= formatCheckboxStateToString(TNVbasic);


    // Prepare form details for summary
    const formDetails = {
      "Localisation": [ formState.Location, formState.Location_Cranio_Caud, formState.Location_axial, formState.Location_side]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      
      "Etat cutané": formState.SkinChanges,
      "A la palpation": [formState.PalpationSize, formState.PalpationDepth, formState.PalpationMob, formState.PalpationPain]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      
      "Status N-V": TNVbasicText,

      "Adénopathie": formState.Adenopathy
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details


   
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  


  return (
    <div className="card">
      <p className="title">Examen clinique: masse</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
          

        <label>Localisation:</label>
          <select name="Location" className="select-input" value={formState.Location} onChange={handleInputChange} >
          <option value="">Localisation générale</option>
            <option value="épaule">épaule</option>
            <option value="bras">bras</option>
            <option value="coude">coude</option>
            <option value="avant-bras">avant-bras</option>
            <option value="poignet">poignet</option>
            <option value="">---</option>
            <option value="région glutéale">région glutéale</option>
            <option value="cuisse">cuisse</option>
            <option value="genou">genou</option>
            <option value="jambe">jambe</option>
            <option value="cheville">cheville</option>
            <option value="AUTRE">AUTRE</option>
          
          </select>

          <select name="Location_Cranio_Caud" className="select-input" value={formState.Location_Cranio_Caud} onChange={handleInputChange} >
            <option value="">Localisation cranio-caudale</option>
            <option value="tiers proximal">tiers proximal</option>
            <option value="tiers moyen">tiers moyen</option>
            <option value="tiers distal">tiers distal</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="Location_axial" className="select-input" value={formState.Location_axial} onChange={handleInputChange} >
           <option value="">Localisation axiale</option>
            <option value="face antérieure">antérieur</option>
            <option value="face antéro-latérale">antéro-latéral</option>
            <option value="face latérale">latéral</option>
            <option value="face postéro-latérale">postéro-latéral</option>
            <option value="face postérieure">postérieur</option>
            <option value="face postéro-médiale">postéro-médial</option>
            <option value="face médiale">médial</option>
            <option value="face antéro-médiale">antéro-médial</option>
            <option value="face postéro-médiale">postéro-médial</option>
            <option value="">-----</option>
            <option value="face palmaire">palmaire</option>
            <option value="face dorsale">dorsal</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <div>

          <input type="radio" name="Location_side" value="côté gauche" checked={formState.Location_side === "côté gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="Location_side" value="côté droit" checked={formState.Location_side === "côté droit"} onChange={handleInputChange} />
            <label> droit </label>

            </div>
        </div>

      <br></br>

      <div>
          
          <select name="SkinChanges" className="select-input" value={formState.SkinChanges} onChange={handleInputChange} >
            <option value="">Etat cutané:</option>
            <option value="sans particularité">sans particularité</option>
            <option value="présence d'un hématome">hématome</option>
            <option value="présence d'une induration">induration</option>
            <option value="présence d'une erythème">erythème</option>
            <option value="présence de phlyctènes">phlyctène</option>
            <option value="AUTRE">AUTRE</option>
          </select>
     
          <select name="PalpationSize" className="select-input" value={formState.PalpationSize} onChange={handleInputChange} >
            <option value="">Palpation, taille:</option>
            <option value="pas de masse palpable">pas de masse palpable</option>
            <option value="présence d'une masse infra-centimétrique">masse infra-centimétrique</option>
            <option value="présence d'une masse supra-centimétrique">masse supra-centimétrique</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="PalpationDepth" className="select-input" value={formState.PalpationDepth} onChange={handleInputChange} >
            <option value="">profondeur:</option>
            <option value="sous-cutanée">sous-cutanée</option>
            <option value="superficielle">superficielle</option>
            <option value="profonde">profonde</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="PalpationMob" className="select-input" value={formState.PalpationMob} onChange={handleInputChange} >
            <option value="">mobilité:</option>
            <option value="mobile">mobile</option>
            <option value="peu mobile">peu mobile</option>
            <option value="fixée">fixée</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="PalpationPain" className="select-input" value={formState.PalpationPain} onChange={handleInputChange} >
            <option value="">Douleurs:</option>
            <option value="pas de douleurs à la palpation">pas de douleurs à la palpation</option>
            <option value="sensible à la palpation">sensible à la palpation</option>
            <option value="douloureuse à la palpation">douloureuse à la palpation</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <div>

<div className="checkbox-options">
        
        <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
        <label>absence de trouble neuro-vasculaire</label>
      
        </div>

        </div>


          <select name="Adenopathy" className="select-input" value={formState.Adenopathy} onChange={handleInputChange} >
            <option value="">Adénopathies:</option>
            <option value="aucune">pas d'adénopathie</option>
            <option value="présence d'adénopathies axillaires / sus-claviculaires">présence d'adénopathies axillaires / sus-claviculaires</option>
            <option value="présence d'adénopathies inguinales">présence d'adénopathies inguinales</option>
           
            <option value="AUTRE">AUTRE</option>
          </select>
          





        </div>




        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default ClinicalExamMass;