import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation  } from "react-router-dom";
import "./styles/app.css";
import Navbar from "./other-components/Navbar";
import MainHome from "./other-components/MainHome";
import OrthopedicsHome from "./orthopedics/OrthopedicsHome";
import Orthopedic_Oncology_Home from "./Orthopedic_Oncology/Orthopedic_Oncology_Home";
import CardiologyHome from "./cardiology/CardiologyHome";

import Privacy from "./other-components/Privacy";

import UpperLimb from "./orthopedics/UpperLimb/UpperLimb"; 
import LowerLimb from "./orthopedics/LowerLimb/LowerLimb";

import OO_Clinical_forms from "./Orthopedic_Oncology/OO_Clinical_forms/OO_Clinical_forms";


import {useUser, useAuth } from "./userContext";

import AnalyticsInitializer from "./other-components/AnalyticsInitializer";
import CookieConsentBanner from "./other-components/CookieConsentBanner";
import ReactGA from 'react-ga4';

const analyticsId = "G-VWQS9L7157";

function AppContent({ consent, isGAInitialized }) {

  const { 
    setCookieConsent,
    isVisibleCookieBanner, 
    setIsVisibleCookieBanner, 
  } = useAuth();

  const location = useLocation();

  useEffect(() => {
    
    if (consent === true && isGAInitialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location, consent, isGAInitialized]);
  

  return (
    <>
      <CookieConsentBanner setCookieConsent={setCookieConsent} isVisibleCookieBanner={isVisibleCookieBanner} setIsVisibleCookieBanner={setIsVisibleCookieBanner} />
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<MainHome />} /> {/* Route for the new general Home */}
          <Route path="/orthopedics/*" element={<OrthopedicsHome />} /> {/* Route for Orthopedics */}
          <Route path="/orthopedics/upper-limb" element={<UpperLimb />} /> {/* Route for Upper Limb */}
          <Route path="/orthopedics/lower-limb" element={<LowerLimb />} /> {/* Route for Lower Limb */}

          <Route path="/Orthopedic_Oncology/*" element={<Orthopedic_Oncology_Home />} /> {/* Route for General Medicine */}
          <Route path="/Orthopedic_Oncology/OO_Clinical_forms" element={<OO_Clinical_forms />} /> {/* Route for Lower Limb */}
         

          <Route path="/cardiology/*" element={<CardiologyHome />} /> {/* Route for Cardiology */}
          <Route path="/privacy" element={<Privacy />} /> {/* Route for Privacy */}
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to Home if the route does not exist */}
        </Routes>
      </div>
    </>
);

}

const initializeGA = (trackingID, isGAInitialized) => {
  if (typeof window !== 'undefined' && !isGAInitialized) {
    console.log("initializeGA initialized.");
    ReactGA.initialize(trackingID, {
      gaOptions: {
        anonymizeIp: true,
        transport: 'beacon'
      }
    });
  }
};


function App() {

  const [consent, setConsent] = useState(null);
  const {isGAInitialized, setIsGAInitialized} = useUser();

  useEffect(() => {

    //To initialize the variable when the page is loaded.
    const storedConsent = localStorage.getItem('cookieConsent');

    const initialConsent = storedConsent === 'true';
    
    setConsent(initialConsent);

    if(initialConsent && !isGAInitialized){
      initializeGA(analyticsId, isGAInitialized);
      setIsGAInitialized(true);
    }

    const handleStorageChange = () => {
      const newConsent = localStorage.getItem('cookieConsent') === 'true';
      setConsent(newConsent);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
      <Router>
        <AnalyticsInitializer consent={consent} isGAInitialized={isGAInitialized} setIsGAInitialized={setIsGAInitialized} />
        <AppContent consent={consent} isGAInitialized={isGAInitialized}  />
      </Router>
  );

}

export default App;