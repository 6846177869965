import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function KneeRecon({setNameForm, onFormSubmit, onSelectionChange}) {
  const [formState, setFormState] = useState({
    SideTKA:"",
    TKAtype: "",
    PatellarComponent:"",
    FemoralFixation:"",
    TibialFixation:"",


    ReconOverzise:"",
    ReconAnteriorOffset:"",
    ReconPosteriorOffset:"",
    JointLineHight:"",
    ReconPatellaCentering:"",
    FemoralLysis:"",
    TibialLysis:""
    
  });


  const [showAdditionalComponent, setshowAdditionalComponent] = useState(false);

  const handleChangeshowAdditionalComponent = (event) => {
    setshowAdditionalComponent(event.target.checked);
  }


  const [kneeExtraComponent, setkneeExtraComponent] = useState({
    "quille fémorale":false,
    "câle fémorale distale": false,
    "câle fémorale postérieure": false,
    "cône fémoral":false,
    "quille tibiale": false,
    "câle tibiale": false,
    "cône tibial": false,
    "AUTRE": false
  
  });

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const [KneeXray, setKneeXray] = useState({
    "Genou face":false,
    "Genou profile":false,
    "axiale rotule":false,
    "Schuss":false,
    "CT-scan":false
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);



  const handleCheckboxChangeKneeXray = (event) => {
    const { name, checked } = event.target;
     
      setKneeXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


 


  const handleCheckboxChangekneeExtraComponent = (event) => {
    const { name, checked } = event.target;
     
      setkneeExtraComponent(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    }


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };


 

  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare form details for summary
    setNameForm("Genou prothétique");

     // Function to convert the state of the checkboxes into a readable string
     const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const kneeExtraComponentText = formatCheckboxStateToString(kneeExtraComponent);
  const KneeXrayText = formatCheckboxStateToString(KneeXray);
    


    const formDetails = {
      // Here we convert the form state into a suitable format for display

      "Genou": formState.SideTKA, 

      "Bilan radiologique": KneeXrayText,
    
      "Arthroplastie de genou": [formState.TKAtype, formState.PatellarComponent, formState.FemoralFixation, formState.TibialFixation]
      .filter((val) => val !== "" && val !== null && val !== undefined)
      .join(', '),
    
      "Composant(s) additionnel(s)": kneeExtraComponentText,

      "Fixation": [formState.FemoralLysis, formState.TibialLysis]
      .filter((val) => val !== "" && val !== null && val !== undefined)
      .join(', '),

      "Reconstruction": [formState.ReconOverzise, formState.ReconAnteriorOffset, formState.ReconPosteriorOffset, formState.JointLineHight, formState.ReconPatellaCentering]
      .filter((val) => val !== "" && val !== null && val !== undefined)
      .join(', '),
    };




    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    // Call the function passed from App.js and pass the details
    onFormSubmit(filteredFormDetails); // Make sure to pass this function correctly from App.js

};

  return (
    <div className="card">
      <p className="title">Bilan radiologique genou prothétique</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div className="checkbox-options">
    
        <div>
        <input type="radio" name="SideTKA" value="gauche" checked={formState.SideTKA === "gauche"} onChange={handleInputChange} />
        <label> gauche </label>

        <input type="radio" name="SideTKA" value="droit" checked={formState.SideTKA === "droit"} onChange={handleInputChange} />
        <label> droit </label>
        </div>
        </div>


        <div>
   
          <input
              type="checkbox"
              name="showXray"
              onChange={handleChangeshowXray}
              
            />
            <label>Renseigner incidences radiologiques</label>

          </div>

          {showXray && (
       <div className="checkbox-options">
       {Object.entries(KneeXray).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeKneeXray} />
           <label>{key}</label>
         </div>
       ))}
     </div>


   )}
          

         



      <div>
          <label className = "title-label-within-card">Type prothèse:</label>
          <select name="TKAtype" className="select-input" value={formState.TKAtype} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="non contrainte">non contrainte</option>
            <option value="non contrainte, CR">non contrainte, CR</option>
            <option value="non contrainte, CS">non contrainte, CS</option>
            <option value="postéro-stabilisée">postéro-stabilisée</option>
            <option value="contrainte, stabilisée varus-valgus">contrainte, stabilisée varus-valgus</option>
            <option value="contrainte, charnière">contrainte, charnière</option>
          </select>
        </div>


        <div className="checkbox-options">
    
        <div>
        <input type="radio" name="PatellarComponent" value="sans resurfaçage rotulien" checked={formState.PatellarComponent === "sans resurfaçage rotulien"} onChange={handleInputChange} />
        <label> sans resurfaçage rotulien </label>
        </div>

        <div>
        <input type="radio" name="PatellarComponent" value="avec resurfaçage rotulien" checked={formState.PatellarComponent === "avec resurfaçage rotulien"} onChange={handleInputChange} />
        <label> avec resurfaçage rotulien </label>
        </div>
        </div>

        <div className="checkbox-options">
        <div>
          <label className = "title-label-within-card">Fixation composant fémoral:</label>
          <div>
          <input type="radio" name="FemoralFixation" value="composant fémoral cimenté" checked={formState.FemoralFixation === "composant fémoral cimenté"} onChange={handleInputChange} />
          <label> cimenté </label>
          
          <br></br>

          <input type="radio" name="FemoralFixation" value="composant fémoral non cimenté" checked={formState.FemoralFixation === "composant fémoral non cimenté"} onChange={handleInputChange} />
          <label> non cimenté </label>
          </div>
        
          </div>
          </div>

          <div className="checkbox-options">
          <div>
          <label className = "title-label-within-card">Fixation composant tibial:</label>
          <div>
          <input type="radio" name="TibialFixation" value="composant tibial cimenté" checked={formState.TibialFixation === "composant tibial cimenté"} onChange={handleInputChange} />
          <label> cimenté </label>

          <br></br>

          <input type="radio" name="TibialFixation" value="composant tibial non cimenté" checked={formState.TibialFixation === "composant tibial non cimenté"} onChange={handleInputChange} />
          <label> non cimenté </label>
          </div>
        
          </div>
          </div>


          <input
       type="checkbox"
       name="kneeExtraComponent"
       onChange={handleChangeshowAdditionalComponent}
       
     />
          <label>Composants additionnels</label>

      <br></br>



     {showAdditionalComponent && (
        <div className="checkbox-options">
      
        {Object.entries(kneeExtraComponent).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangekneeExtraComponent} />
            <label>{key}</label>
          </div>
        ))}
      </div>
   )}

   <br></br>

   <div>
       <label className = "title-label-within-card" > Ostélyse/descellement: </label>
          <select name="FemoralLysis" className="select-input" value={formState.FemoralLysis} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="pas de signe de descellement composant fémoral">pas de signe de descellement composant fémoral</option>
            <option value="liseré composant fémoral, stable par rapport aux comparatifs">liseré composant fémoral, stable par rapport aux comparatifs</option>
            <option value="liseré composant fémoral, progressif par rapport aux comparatifs">liseré composant fémoral, progressif par rapport aux comparatifs</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="TibialLysis" className="select-input" value={formState.TibialLysis} onChange={handleInputChange} >
            <option value="">-</option>
            <option value="pas de signe de descellement composant tibial">pas de signe de descellement composant tibial</option>
            <option value="liseré composant tibial, stable par rapport aux comparatifs">liseré composant tibial, stable par rapport aux comparatifs</option>
            <option value="liseré composant tibial, progressif par rapport aux comparatifs">liseré composant tibial, progressif par rapport aux comparatifs</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        </div>


   
   <label className = "title-label-within-card" > Reconstruction: </label>


   <div>
      
          <select name="ReconOverzise" className="select-input" value={formState.ReconOverzise} onChange={handleInputChange} >
            <option value="">Débord prothétique:</option>
            <option value="pas de débord prothétique">pas de débord prothétique</option>
            <option value="débord composant fémoral, médial">débord composant fémoral, médial</option>
            <option value="débord composant fémoral, latéral">débord composant fémoral, latéral</option>
            <option value="débord composant tibial, médial">débord composant tibial, médial</option>
            <option value="débord composant tibial, latéral">débord composant tibial, latéral</option>
            <option value="AUTRE">AUTRE</option>
          </select>


          <select name="ReconAnteriorOffset" className="select-input" value={formState.ReconAnteriorOffset} onChange={handleInputChange} >
            <option value="">Offset antérieur:</option>
            <option value="Offset antérieur physiologique">physiologique</option>
            <option value="Offset antérieur augmenté">augmenté</option>
            <option value="Offset antérieur diminué">diminué</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="ReconPosteriorOffset" className="select-input" value={formState.ReconPosteriorOffset} onChange={handleInputChange} >
            <option value="">Offset postérieur:</option>
            <option value="Offset postérieur physiologique">physiologique</option>
            <option value="Offset postérieur augmenté">augmenté</option>
            <option value="Offset postérieur diminué">diminué</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="JointLineHight" className="select-input" value={formState.JointLineHight} onChange={handleInputChange} >
            <option value="">Hauteur interligne articulaire:</option>
            <option value="hauteur interligne physiologique">physiologique</option>
            <option value="interligne proximalisé">proximalisé</option>
            <option value="interligne distalisé">distalisé</option>
          </select>

          <select name="ReconPatellaCentering" className="select-input" value={formState.ReconPatellaCentering} onChange={handleInputChange} >
            <option value="">Positionnement axial rotule:</option>
            <option value="rotule centrée dans trochlée prothétique">centrée dans trochlée prothétique</option>
            <option value="rotule tiltée latéralement">tilt latéral</option>
            <option value="rotule subluxée latéralement">subluxation latérale</option>
            <option value="AUTRE">AUTRE</option>
          </select>




        </div>



        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default KneeRecon;