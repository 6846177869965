import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/navbar.css"; 

function Navbar() {
  return (
    <nav className="navbar-container">
      <Link to="/" className="nav-link navbar-left"> Home </Link>
      <Link to="/privacy" className="nav-link navbar-left secondItem"> Privacy </Link>
    </nav>
  );
}

export default Navbar;