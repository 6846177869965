import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";
import Modal from "../../../../other-components/Modal.js";

function ElbowForearmXRayTraumat({setNameForm, onFormSubmit, onSelectionChange}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    side: '',
    ElbowDislocation: '',
    Monteggia:'',
    Olecranon:'',
    RadialHead:'',
    Coronoid_ReaganAndMorrey:'',
    Coronoid_ODriscol:'',
    ForearmFracture:'',
    Distal_radius:'',
    Radial_Height:'',
    Radial_inclination:'',
    Volar_tilt:''

  });

  const [XRayWorkUp, setXRayWorkUp] = useState({
   
    "coude face": false,
    "coude profil": false,
    "avant bras face": false,
    "avant bras profil": false,
    "poignet face": false,
    "poignet profil": false,
    "CT-scan": false,
  
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []); 

  const handleCheckboxChangeXRayWorkUp = (event) => {
    const { name, checked } = event.target;
     
      setXRayWorkUp(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Coude & avant-bras");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const XRayWorkUpText = formatCheckboxStateToString(XRayWorkUp);
  


    // Prepare form details for summary
    const formDetails = {
      "Côté": formState.side,
      "Bilan radiologique": XRayWorkUpText,
      "Coude": formState.ElbowDislocation,
      "Lésion type Monteggia": formState.Monteggia,
      "Coronoïde (Reagan & Morrey)": formState.Coronoid_ReaganAndMorrey,
      "Coronoïde (O'Driscoll)": formState.Coronoid_ODriscol,
    
      "Tête radiale": formState.RadialHead,
      
      "Avant-bras": formState.ForearmFracture,
      "Olécrâne": formState.Olecranon,
      "Radius distal": [formState.Distal_radius, (formState.Volar_tilt ? `bascule dorsale ${formState.Volar_tilt}°` : ""), (formState.Radial_inclination ? `inclinaison radiale ${formState.Radial_inclination}°` : ""), (formState.Radial_Height ? `hauteur radiale ${formState.Radial_Height}mm` : "")].filter(function(val) {
        return val;
      }).join(', '),
    
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Coude et avant-bras, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>



      <div className="checkbox-options">
            <div>
             <input type="radio" name="side" value="gauche" checked={formState.side === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="side" value="droit" checked={formState.side === "droit"} onChange={handleInputChange} />
            <label> droit </label>
            </div>
        </div>

      <div>

      <br></br>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
       <div className="checkbox-options">
       
       {Object.entries(XRayWorkUp).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeXRayWorkUp} />
           <label>{key}</label>
         </div>
       ))}
     </div>

   )}
 
   

      <br></br>

        <div>
        
          <select name="ElbowDislocation" className="select-input" value={formState.ElbowDislocation} onChange={handleInputChange} >
            <option value="">Luxation coude:</option>
            <option value="Rapport articulaires conservés">Rapport articulaires conservés</option>
            <option value="luxation postérieure">luxation postérieure</option>
            <option value="luxation médiale">luxation médiale</option>
            <option value="luxation latérale">luxation latérale</option>
            <option value="luxation divergente">luxation divergente</option>
            <option value="luxation perchée">luxation perchée</option>
           
          </select>
      
    
          <select name="Monteggia" className="select-input" value={formState.Monteggia} onChange={handleInputChange} >
            <option value="">Lésion type Monteggia:</option>
            <option value="fracture 1/3 proximal ulna et luxation antérieure tête radiale, Bado I">fracture 1/3 proximal ulna et luxation antérieure tête radiale, Bado I</option>
            <option value="fracture 1/3 proximal ulna et luxation postérieure tête radiale, Bado II">fracture 1/3 proximal ulna et luxation postérieure tête radiale, Bado II</option>
            <option value="fracture métaphysaire ulna et luxation latérale tête radiale, Bado III">fracture métaphysaire ulna et luxation latérale tête radiale, Bado III</option>
            <option value="fractures 1/3 proximaux radius ulna associés à luxation tête radiale, Bado IV">fractures 1/3 proximaux radius ulna associés à luxation tête radiale, Bado IV</option>

            </select>
            

          <select name="Olecranon" className="select-input" value={formState.Olecranon} onChange={handleInputChange} >
            <option value="">Fracture olécrâne selon Mayo:</option>
            <option value="fracture unifocale non déplacée, Mayo Ia">unifocale non déplacée, Mayo Ia</option>
            <option value="fracture multi-fragmentaire non déplacée, Mayo Ib">multi-fragmentaire non déplacée, Mayo Ib</option>
            <option value="fracture unifocale déplacée, Mayo IIa">unifocale déplacée, Mayo IIa</option>
            <option value="fracture multi-fragmentaire déplacée, Mayo IIb">multi-fragmentaire déplacée, Mayo IIb</option>
            <option value="fracture unifocale avec instabilité ulno-humérale, Mayo IIIa">unifocale avec instabilité ulno-humérale, Mayo IIIa</option>
            <option value="fracture multi-fragmentaire avec instabilité ulno-humérale, Mayo IIIb">multi-fragmentaire avec instabilité ulno-humérale, Mayo IIIb</option>
            </select>

        
          <select name="RadialHead" className="select-input" value={formState.RadialHead} onChange={handleInputChange} >
            <option value="">Fracture tête radiale selon Mason:</option>
            <option value="fracture avec déplacement < 2mm, Mason I ">déplacement {'<'} 2mm, Mason I </option>
            <option value="fracture marginale, Mason I">marginale, Mason I </option>
            <option value="fracture articulaire partielle avec déplacement > 2mm, Mason II">articulaire partielle avec déplacement {'>'} 2mm, Mason II</option>
            <option value="fracture au niveau du col radial, avec déplacement > 2mm, Mason II">au niveau du col radial, avec déplacement {'>'} 2mm, Mason II</option>
            <option value="fracture au niveau du col radial, avec déplacement complet, Mason IIIa">au niveau du col radial, avec déplacement complet, Mason IIIa</option>
            <option value="fracture articulaire complète, avec plus de 2 fragments, Mason IIIb">articulaire complète, avec plus de 2 fragments, Mason IIIb</option>
            <option value="fracture articulaire complète, avec impaction et angulation d'un fragment articulaire, Mason IIIc">articulaire complète, avec impaction et angulation d'un fragment articulaire, Mason IIIc</option>
        
            </select>

        
          <select name="Coronoid_ReaganAndMorrey" className="select-input" value={formState.Coronoid_ReaganAndMorrey} onChange={handleInputChange} >
            <option value="">Fracture coronoïde selon Reagan & Morrey:</option>
            <option value="fracture extremité, type I">extremité, type I</option>
            <option value="fracture < 50% hauteur, type II">{'<'}50% hauteur, type II</option>
            <option value="fracture > 50% hauteur, type III">{'>'} 50% hauteur, type III</option>
           
            </select>

       
          <select name="Coronoid_ODriscol" className="select-input" value={formState.Coronoid_ODriscol} onChange={handleInputChange} >
            <option value="">Fracture coronoïde selon O'Driscoll:</option>
            <option value="fracture extremité, sous-type I (<2mm)">extremité, sous-type I {'(<'}2mm{')'}</option>
            <option value="fracture extremité, sous-type II (>2mm)">extremité, sous-type II {'(>'}2mm{')'}</option>
            <option value="fracture antéro-médiale, sous-type I (facette)">antéro-médiale, sous-type I {'('}facette{')'}</option>
            <option value="fracture antéro-médiale, sous-type II (facette et extremité)">antéro-médiale, sous-type II {'('}facette et extremité{')'}</option>
            <option value="fracture antéro-médiale, sous-type III (bordure et tubercule sublime)">antéro-médiale, sous-type III {'('}bordure et tubercule sublime{')'}</option>
            <option value="fracture basale, sous-type I (corps et base coronoide)">basale, sous-type I {'('}corps et base coronoide{')'}</option>
            <option value="fracture basale, sous-type II (corps et base coronoide, trans-ulnaire)">basale, sous-type II {'('}corps et base coronoide, trans-ulnaire{')'}</option>
           
            </select>

       
          <select name="ForearmFracture" className="select-input" value={formState.ForearmFracture} onChange={handleInputChange} >
            <option value="">Lésions avant-bras:</option>
            <option value="fracture simple ulna, radius intact (AO 22-A1)">fracture simple ulna, radius intact (AO 22-A1)</option>
            <option value="fracture simple radius, ulna intact (AO 22-A2)">fracture simple radius, ulna intact (AO 22-A2)</option>
            <option value="fractures simples radius et ulna (AO 22-A3)">fractures simples radius et ulna (AO 22-A3)</option>

            <option value="fracture ulna type wedge, radius intact (AO 22-B1)"> fracture ulna type wedge, radius intact (AO 22-B1)</option>
            <option value="fracture radius type wedge, ulna intact (AO 22-B2)">fracture radius type wedge, ulna intact (AO 22-B2)</option>
            <option value="fractures des deux os de l'avant bras, une simple et une type wedge (AO 22-B3)">fractures des deux os de l'avant bras, une simple et une type wedge (AO 22-B3)</option>

            <option value="fracture complexe ulna, radius simple (AO 22-C1)"> fracture complexe ulna, radius simple (AO 22-C1)</option>
            <option value="fracture complexe radius, ulna simple (AO 22-C2)">fracture complexe radius, ulna simple (AO 22-C2)</option>
            <option value="fractures des deux os de l'avant bras, radius et ulna complexes (AO 22-C3)">fractures des deux os de l'avant bras, radius et ulna complexes (AO 22-C3)</option>
            <option value="Fracture distale radius associée à lésion DRUJ (Galeazzi)">Fracture distale radius associée à lésion DRUJ (Galeazzi)</option>
          </select>
        

          <select name="Distal_radius" className="select-input" value={formState.Distal_radius} onChange={handleInputChange} >
            <option value="">Fracture radius distal:</option>
            <option value="fracture extra-articulaire, déplacement dorsal (Pouteaux-Colles)">extra-articulaire, déplacement dorsal {'("'}Pouteaux-Colles{')"'}</option>
            <option value="fracture extra-articulaire, déplacement palmaire (Smith)">extra-articulaire, déplacement palmaire {'("'}Smith{')"'}</option>
            <option value="fracture styloide radiale (Chauffeur)">styloide radiale {'("'}Chauffeur{')"'}</option>

            <option value="Fracture-luxation radio-carpienne, palmaire (Barton palmaire)">Fracture-luxation radio-carpienne, palmaire {'("'}Barton palmaire{')"'}</option>
            <option value="Fracture-luxation radio-carpienne, dorsale (Barton dorsale)">Fracture-luxation radio-carpienne, dorsale {'("'}Barton dorsale{')"'}</option>
            <option value="fracture-impaction fosse lunatum (Die-punch)">fracture-impaction fosse lunatum {'("'}Die-punch{')"'}</option>

            <option value="">---Classification selon Frykman---</option>
            <option value="fracture métaphysaire transverse, Frykman I">métaphyse transverse, Frykman I</option>
            <option value="fracture métaphysaire transverse et styloïde ulnaire, Frykman II">métaphyse transverse et styloïde ulnaire, Frykman II</option>
            <option value="fracture intra-articulaire, Frykman III">intra-articulaire, Frykman III</option>
            <option value="fracture intra-articulaire et styloïde ulnaire, Frykman IV">intra-articulaire et styloïde ulnaire, Frykman IV</option>
            <option value="fracture transverse au niveau DRUJ, Frykman V">transverse au niveau DRUJ, Frykman V</option>
            <option value="fracture transverse au niveau DRUJ et styloïde ulnaire, Frykman VI">transverse au niveau DRUJ et styloïde ulnaire, Frykman VI</option>
            <option value="fracture comminutive intra-articulaire et au niveau DRUJ, Frykman VII">comminutive intra-articulaire et au niveau DRUJ, Frykman VII</option>
            <option value="comminutive intra-articulaire et au niveau DRUJ + styloïde ulnaire, Frykman VII">comminutive intra-articulaire et au niveau DRUJ + styloïde ulnaire, Frykman VII</option>

          </select>
        </div>

        {formState.Distal_radius && (
        
        <div>
          <label>Bascule dorsale - Inclinaison radiale - Hauteur radiale:</label><br></br>
          <input type="number" className="number-input" name="Volar_tilt"  placeholder="tilt" value={formState.Volar_tilt} onChange={handleInputChange} />
          <input type="number" className="number-input" name="Radial_inclination" placeholder="IR" value={formState.Radial_inclination} onChange={handleInputChange} />
          <input type="number" className="number-input" name="Radial_Height" placeholder="HR" value={formState.Radial_Height} onChange={handleInputChange} />
        
        </div>

          )}
       



        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
    
    </div>
  );
}

export default ElbowForearmXRayTraumat;