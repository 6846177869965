import React, { useState, useEffect } from "react";
import "../../styles/app.css"; 
import InitialCard from "./lowerLimb-initial-cards/InitialCard";
import Summary from "../../other-components/Summary";
import TextSummary from "../../other-components/TextSummary";

/*_____________________CHECKBOX CARDS_____________________*/
import CheckboxPhysicalExam from "./lowerLimb-checkbox-cards/CheckboxPhysicalExam";
import CheckboxMedicalHistory from "./lowerLimb-checkbox-cards/CheckboxMedicalHistory";
import CheckboxXRays from "./lowerLimb-checkbox-cards/CheckboxXRays";
import CheckboxDxTreatment from "./lowerLimb-checkbox-cards/DxTreatment";
/*________________________________________________________*/


import NVstatusMS from "./lowerLimb-specific-cards/physical-exam/NVstatusMS";
import NVstatusMI from "./lowerLimb-specific-cards/physical-exam/NVstatusMI";
import GeneralPhysicalExam from "./lowerLimb-specific-cards/physical-exam/GeneralPhysicalExam";
import ClinicalExamMass from "./lowerLimb-specific-cards/physical-exam/ClinicalExamMass";


import ClinicalMass from "./lowerLimb-specific-cards/medical-history/ClinicalMass";
import IncidentalFinding from "./lowerLimb-specific-cards/medical-history/IncidentalFinding";

import Imaging_Lipo from "./lowerLimb-specific-cards/x-rays/Imaging_Lipo";
import Imaging_Chondro from "./lowerLimb-specific-cards/x-rays/Imaging_Chondro";
import XrayOncoLowerLimb from "./lowerLimb-specific-cards/x-rays/XrayOncoLowerLimb";
import XrayOncoUpperLimb from "./lowerLimb-specific-cards/x-rays/XrayOncoUpperLimb";
import Mirels_Score from "./lowerLimb-specific-cards/x-rays/Mirels_Score";

import Conservative_General_LL from "./lowerLimb-specific-cards/DxTreatment/Conservative_General_LL"
import Operative_General_LL from "./lowerLimb-specific-cards/DxTreatment/Operative_General_LL";


/*______________________INITIAL CARDS______________________*/
const description1 = "Anamnèses spécifiques, orthopédie oncologique";
const description2 = "Examens cliniques spécifiques, orthopédie oncologique";
const description3 = "Bilan radiologique, orthopédie oncologique";
const description4 = "Diagnostics & plans de traitement"

const name1 = "Anamnèse";
const name2 = "Examen clinique";
const name3 = "Radiologie";
const name4 = "Dx et traitements";

const image1 = "../medical-history.png";
const image2 = "../physical-exam.png";
const image3 = "../x-rays.png";
const image4="../Treatment.webp";
/*_______________________________________________________________________________________________*/

function OO_Clinical_forms() {
  const [formDetails, setFormDetails] = useState(null);
  const [nameForm, setNameForm] = useState("");
  const [isFormSended, setIsFormSended] = useState(false);
  const [isText, setIsText] = useState(false);
/*_______________________________________________________________________________________________*/
  /*PHYSICAL EXAM BOX*/

  const [isVisibleCheckboxPhysical, setIsVisibleCheckboxPhysical] = useState(false);
  const [showSpecificPhysicalForm, setShowSpecificPhysicalForm] = useState(false);
  const [isVerticalMode, setIsVerticalMode] = useState(false);
  const [selectedPhysicalOptions, setSelectedPhysicalOptions] = useState({
    Généralités: false,
    "Status masse": false,
    "Status neuro-vasculaire MS": false,
    "Status neuro-vasculaire MI": false,

    
  });

   ////////////////////////////////////////////////////////////

  // States for selections in third-cards
  const [selectedThirdCardsOptions, setSelectedThirdCardsOptions] = useState({
    "Masse clinique": false,
    "Lésion, découverte fortuite": false,

    Généralités: false,
    "Status masse": false,
    "Status neuro-vasculaire MS": false,
    "Status neuro-vasculaire MI": false,

    "Description tumorale, MS": false,
    "Description tumorale, MI": false,
    "Tumeur Lipo, tissus mous": false,
    "Tumeur chondroïde, intra-osseuse": false,
    "Score de Mirels": false,
  });

  // Count the sections in third-cards
  const countThirdCardsSelections = () => {
    return Object.values(selectedThirdCardsOptions).filter(Boolean).length;
  };  

    // Check if vertical mode should be activated.
  useEffect(() => {
    const handleResize = () => {
      const totalThirdCardsSelections = countThirdCardsSelections();
      const isWindowWideEnough = window.innerWidth > 940;

      setIsVerticalMode(totalThirdCardsSelections >= 4 && isWindowWideEnough);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Execute immediately after mounting
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedThirdCardsOptions]);

  // To reflect the changes in the selections
useEffect(() => {
  const totalThirdCardsSelections = countThirdCardsSelections();
  
  const isWindowWideEnough = window.innerWidth > 940;

  setIsVerticalMode(totalThirdCardsSelections >= 4 && isWindowWideEnough);
}, [selectedThirdCardsOptions]); // Only depends of selectedThirdCardsOptions

  // Function to reflect the changes in third-cards
  const handleSelectedThirdCardsChange = (optionKey, isSelected) => {
    setSelectedThirdCardsOptions(prevState => {
      // Only update if the current value is different to the previous one. (True --> False) // (False --> True)
      if (prevState[optionKey] !== isSelected) {
        return {
          ...prevState,
          [optionKey]: isSelected
        };
      }
      return prevState;
    });
  };

  ////////////////////////////////////////////////////////////

  const handleSelectedPhysicalChange = (options) => {
    setSelectedPhysicalOptions(options);
  };

  const toggleVisiblePhysicalForm = () => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    setShowSpecificPhysicalForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxPhysical) {
      setSelectedPhysicalOptions({
        Généralités: false,
       "Status masse": false,
       "Status neuro-vasculaire MS": false,
        "Status neuro-vasculaire MI": false,
        
      });
    }
  }, [isVisibleCheckboxPhysical]);

  useEffect(() => {
    const anySelected = Object.values(selectedPhysicalOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificPhysicalForm(false);
    }
  }, [selectedPhysicalOptions]);

/*_______________________________________________________________________________________________*/
  /*MEDICAL HISTORY*/

  const [isVisibleCheckboxHistory, setIsVisibleCheckboxHistory] = useState(false);
  const [showSpecificHistoryForm, setShowSpecificHistoryForm] = useState(false);
  const [selectedHistoryOptions, setSelectedHistoryOptions] = useState({

    "Masse clinique": false,
    "Lésion, découverte fortuite": false,
  });

  const handleSelectedHistoryChange = (options) => {
    setSelectedHistoryOptions(options);
  };

  const toggleVisibleHistoryForm = () => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    setShowSpecificHistoryForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxHistory) {
      setSelectedHistoryOptions({
        "Masse clinique": false,
       "Lésion, découverte fortuite": false,
      });
    }
  }, [isVisibleCheckboxHistory]);

  useEffect(() => {
    const anySelected = Object.values(selectedHistoryOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificHistoryForm(false);
    }
  }, [selectedHistoryOptions]);

/*_______________________________________________________________________________________________*/
  /*X-RAYS*/

  const [isVisibleCheckboxXRays, setIsVisibleCheckboxXRays] = useState(false);
  const [showSpecificXRaysForm, setShowSpecificXRaysForm] = useState(false);
  const [selectedXRaysyOptions, setSelectedXRaysOptions] = useState({
    "Description tumorale, MS": false,
    "Description tumorale, MI": false,
    "Tumeur Lipo, tissus mous": false,
    "Tumeur chondroïde, intra-osseuse": false,
    "Score de Mirels": false,
  });

  const handleSelectedXRaysChange = (options) => {
    setSelectedXRaysOptions(options);
  };

  const toggleVisibleXRaysForm = () => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    setShowSpecificXRaysForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxXRays) {
      setSelectedXRaysOptions({
        "Description tumorale, MS": false,
        "Description tumorale, MI": false,
        "Tumeur Lipo, tissus mous": false,
        "Tumeur chondroïde, intra-osseuse": false,
        "Score de Mirels": false,
      });
    }
  }, [isVisibleCheckboxXRays]);

  useEffect(() => {
    const anySelected = Object.values(selectedXRaysyOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificXRaysForm(false);
    }
  }, [selectedXRaysyOptions]);

  /*_______________________________________________________________________________________________*/
  /*Dx & Treatment*/

  const [isVisibleCheckboxDxTreatment, setIsVisibleCheckboxDxTreatment] = useState(false);
  const [showSpecificDxTreatmentForm, setShowSpecificDxTreatmentForm] = useState(false);
  const [selectedDxTreatmentOptions, setSelectedDxTreatmentOptions] = useState({
    "TTT conservateur générique": false,
    "TTT chirurgical, traumat générique": false,
  });

  const handleSelectedDxTreatmentChange = (options) => {
    setSelectedDxTreatmentOptions(options);
  };

  const toggleVisibleDxTreatmentForm = () => {
    const anySelected = Object.values(selectedDxTreatmentOptions).some((value) => value);
    setShowSpecificDxTreatmentForm(anySelected);
  };

  useEffect(() => {
    if (!isVisibleCheckboxDxTreatment) {
      setSelectedDxTreatmentOptions({
        "TTT conservateur générique": false,
        "TTT chirurgical, traumat générique": false,
      });
    }
  }, [isVisibleCheckboxDxTreatment]);

  useEffect(() => {
    const anySelected = Object.values(selectedDxTreatmentOptions).some((value) => value);
    if (!anySelected) {
      setShowSpecificDxTreatmentForm(false);
    }
  }, [selectedDxTreatmentOptions]);

/*_______________________________________________________________________________________________*/



/*_______________________________________________________________________________________________*/

  const handleFormSubmit = (details, name, text) => {
    setFormDetails(details);
    setNameForm(name);
    setIsFormSended(true);
    setIsText(text);
  };

  return (
    
    <>
    
    
      {!isFormSended && (
        <div className={`container ${isVerticalMode ? 'vertical-mode' : ''}`}>
          <div className="first-cards">
            <InitialCard
              name={name1}
              description={description1}
              image={image1}
              visible={isVisibleCheckboxHistory}
              setIsVisible={setIsVisibleCheckboxHistory}
            />
            <InitialCard
              name={name2}
              description={description2}
              image={image2}
              visible={isVisibleCheckboxPhysical}
              setIsVisible={setIsVisibleCheckboxPhysical}
            />
            <InitialCard
              name={name3}
              description={description3}
              image={image3}
              visible={isVisibleCheckboxXRays}
              setIsVisible={setIsVisibleCheckboxXRays}
            />

            <InitialCard
              name={name4}
              description={description4}
              image={image4}
              visible={isVisibleCheckboxDxTreatment}
              setIsVisible={setIsVisibleCheckboxDxTreatment}
            />
          </div>

          <div className="second-cards">
            {isVisibleCheckboxHistory && (
              <CheckboxMedicalHistory
                selectedOptions={selectedHistoryOptions}
                onSelectedOptionsChange={handleSelectedHistoryChange}
                handleButtonClick={toggleVisibleHistoryForm}
                showSpecificForm={showSpecificHistoryForm}
              />
            )}

            {isVisibleCheckboxPhysical && (
              <CheckboxPhysicalExam
                selectedOptions={selectedPhysicalOptions}
                onSelectedOptionsChange={handleSelectedPhysicalChange}
                handleButtonClick={toggleVisiblePhysicalForm}
                showSpecificForm={showSpecificPhysicalForm}
              />
            )}
            {isVisibleCheckboxXRays && (
              <CheckboxXRays
                selectedOptions={selectedXRaysyOptions}
                onSelectedOptionsChange={handleSelectedXRaysChange}
                handleButtonClick={toggleVisibleXRaysForm}
                showSpecificForm={showSpecificXRaysForm}
              />
            )}

            {isVisibleCheckboxDxTreatment && (
              <CheckboxDxTreatment
                selectedOptions={selectedDxTreatmentOptions}
                onSelectedOptionsChange={handleSelectedDxTreatmentChange}
                handleButtonClick={toggleVisibleDxTreatmentForm}
                showSpecificForm={showSpecificDxTreatmentForm}
              />
            )}
          </div>



          <div className="third-cards">
            {showSpecificHistoryForm && (
              <>
                
                {selectedHistoryOptions["Masse clinique"] && (
                  <ClinicalMass
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Masse clinique", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Masse clinique", isSelected)}
                  />
                )}
               
                {selectedHistoryOptions["Lésion, découverte fortuite"] && (
                  <IncidentalFinding
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Lésion, découverte fortuite", true)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Lésion, découverte fortuite", isSelected)}
                  />
                )}
              </>
            )}

            {showSpecificPhysicalForm && (
              <>
                {selectedPhysicalOptions.Généralités && (
                  <GeneralPhysicalExam
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Généralités", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Généralités", isSelected)}
                  />
                )}
                {selectedPhysicalOptions["Status masse"] && (
                  <ClinicalExamMass
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Status masse", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Status masse", isSelected)}
                  />
                )}  
                


                {selectedPhysicalOptions["Status neuro-vasculaire MS"] && (
                  <NVstatusMS
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Status neuro-vasculaire MS", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Status neuro-vasculaire MS", isSelected)}
                  />
                )}
                {selectedPhysicalOptions["Status neuro-vasculaire MI"] && (
                  <NVstatusMI
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Status neuro-vasculaire MI", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Status neuro-vasculaire MI", isSelected)}
                  />
                )}
             
              </>
            )}



            {showSpecificXRaysForm && (
              <>
                {selectedXRaysyOptions["Description tumorale, MS"] && (
                  <XrayOncoUpperLimb
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Description tumorale, MS", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Description tumorale, MS", isSelected)}
                  />
                )}
                {selectedXRaysyOptions["Description tumorale, MI"] && (
                  <XrayOncoLowerLimb
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Description tumorale, MI", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Description tumorale, MI", isSelected)}
                  />
                )}
                {selectedXRaysyOptions["Tumeur Lipo, tissus mous"] && (
                  <Imaging_Lipo
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Tumeur Lipo, tissus mous", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Tumeur Lipo, tissus mous", isSelected)}
                  />
                )}
                {selectedXRaysyOptions["Tumeur chondroïde, intra-osseuse"] && (
                  <Imaging_Chondro
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Bilan radiologique: lésion chondroïde", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Tumeur chondroïde, intra-osseuse", isSelected)}
                  />
                )}
                
             
                {selectedXRaysyOptions["Score de Mirels"] && (
                  <Mirels_Score
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "Score de Mirels", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("Score de Mirels", isSelected)}
                  />
                )}
              </>
            )}

            {showSpecificDxTreatmentForm && (
              <>
                {selectedDxTreatmentOptions["TTT conservateur générique"] && (
                  <Conservative_General_LL
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "TTT conservateur générique", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("TTT conservateur générique", isSelected)}
                  />
                )}
                {selectedDxTreatmentOptions["TTT chirurgical, traumat générique"] && (
                  <Operative_General_LL
                    setNameForm={setNameForm}
                    onFormSubmit={(details) => handleFormSubmit(details, "TTT chirurgical, traumat générique", false)}
                    onSelectionChange={(isSelected) => handleSelectedThirdCardsChange("TTT chirurgical, traumat générique", isSelected)}
                  />
                )}
               
              </>
            )}
          </div>
        </div>
      )}

      {isFormSended && (
        isText ? (
          <TextSummary setIsFormSended={setIsFormSended} formDetails={formDetails} />
        ) : (
          <Summary nameSpecificCard={nameForm} setIsFormSended={setIsFormSended} formDetails={formDetails} />
        )
      )}
    </>
  );
}

export default OO_Clinical_forms;