import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function NVstatusMI({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({
    vascularLowerLimb:"pouls périphériques palpés, temps de recoloration inférieur à 3 secondes",
    tibialPostLeft: "",
    dorsalisPedisLeft: "",
    refillTimeLeft: "",
    tibialPostRight: "",
    dorsalisPedisRight: "",
    refillTimeRight: "",

    neurologySensLowerLimb: "pas de déficit",
    neuroSensTerritoryLowerLimb: "",
    neuroSensTerritorySideLowerLimb: "",

    neurologyMotorLowerLimb: "pas de déficit",
    neuroMotorTerritoryLowerLimb: "",
    neuroMotorTerritorySideLowerLimb: "",

    quadRfx:"",
    achilleRfx:""

  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Physical exam [Wrist]");
    // Here is where you would prepare the object with the form details to send to the onFormSubmit function
    const formDetails = {
      "Status vasculaire":[formState.vascularLowerLimb, formState.tibialPostLeft, formState.dorsalisPedisLeft, formState.refillTimeLeft, formState.tibialPostRight, formState.dorsalisPedisRight, formState.refillTimeRight].filter(function(val) {
        return val;
      }).join('; '),

     

      "Status sensitif":[formState.neurologySensLowerLimb,formState.neuroSensTerritoryLowerLimb, formState.neuroSensTerritorySideLowerLimb].filter(function(val) {
        return val;
      }).join(' '),

  

      "Status moteur": [formState.neurologyMotorLowerLimb, formState.neuroMotorTerritoryLowerLimb, formState.neuroMotorTerritorySideLowerLimb].filter(function(val) {
        return val;
      }).join(', '),

    

      "Réflexe quadricipitaux": formState.quadRfx,
      "Réflexe achilléens": formState.achilleRfx
 

    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    onFormSubmit(filteredFormDetails); // Make sure this prop is passed correctly from App.js
  }

  return (
    <div className="card">
      <p className="title">Status neuro-vasculaire membres inférieurs</p>
      <form className="specific-form" onSubmit={handleSubmit}>
        
      <div>
        <label className="title-label-within-card">Trouble vasculaire:</label>
        <select className="select-input" name="vascularLowerLimb" value={formState.vascularLowerLimb} onChange={handleInputChange} >
          <option value="pouls périphériques palpés, temps de recoloration inférieur à 3 secondes">aucun</option>
          <option value="trouble vasculaire côté gauche">trouble vasculaire côté gauche</option>
          <option value="trouble vasculaire côté droit">trouble vasculaire côté droit</option>
          <option value="trouble vasculaire bilatéraux">troubles vasculaires bilatéraux</option>
      
        </select>
      </div>

  

      {formState.vascularLowerLimb === "trouble vasculaire côté gauche" && (
        <>
          <div> 
            <p>Pouls pédieux G:  &emsp;
            
            <input type="radio" name="dorsalisPedisLeft" value="pouls pédieux présent (G)" checked={formState.dorsalisPedisLeft === "pouls pédieux présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="dorsalisPedisLeft" value="pouls pédieux non perçu (G)" checked={formState.dorsalisPedisLeft === "pouls pédieux non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>
              </p>

              <p>Pouls tibial post G:  &ensp;
            
            <input type="radio" name="tibialPostLeft" value="pouls tibial post. présent (G)" checked={formState.tibialPostLeft === "pouls tibial post. présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="tibialPostLeft" value="pouls tibial post. non perçu (G)" checked={formState.tibialPostLeft === "pouls tibial post. non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>

              </p>

          
            <p>Tps recoloration G: &ensp; 
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration inférieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration inférieur à 3 secondes (G)"} onChange={handleInputChange}/>
              <label> {'>'} 3''</label>
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration supérieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration supérieur à 3 secondes (G)"} onChange={handleInputChange} />
              <label> {'<'} 3''' </label>
              </p>
          </div>
        </>
      )}

      {formState.vascularLowerLimb === "trouble vasculaire côté droit" && (
        <>
          <div> 
          <p>Pouls pédieux D: &emsp;
            
            <input type="radio" name="dorsalisPedisRight" value="pouls pédieux présent (D)" checked={formState.dorsalisPedisRight === "pouls pédieux présent (D)"} onChange={handleInputChange} required/>
            <label> + </label>
        
            <input type="radio" name="dorsalisPedisRight" value="pouls pédieux non perçu (D)" checked={formState.dorsalisPedisRight === "pouls pédieux non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>


            <p>Pouls tibial post D: &ensp;
          
            <input type="radio" name="tibialPostRight" value="pouls tibial post. présent (D)" checked={formState.tibialPostRight === "pouls tibial post. présent (D)"} onChange={handleInputChange}required/>
            <label> + </label>
          
            <input type="radio" name="tibialPostRight" value="pouls tibial post. non perçu (D)" checked={formState.tibialPostRight === "pouls tibial post. non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>

            <p>Tps recoloration, D: &ensp;
            
              <input type="radio" name="refillTimeRight" value="temps de recoloration inférieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration inférieur à 3 secondes (D)"} onChange={handleInputChange}/>
              <label> {'>'} 3'' </label>
          
              <input type="radio" name="refillTimeRight" value="temps de recoloration supérieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration supérieur à 3 secondes (D)"} onChange={handleInputChange} />
              <label> {'<'} 3'' </label>

              </p>
          </div>
        </>
      )}
        
      {formState.vascularLowerLimb === "trouble vasculaire bilatéraux" && (
        <>
           <div> 
            <p>Pouls pédieux G:  &emsp;
            
            <input type="radio" name="dorsalisPedisLeft" value="pouls pédieux présent (G)" checked={formState.dorsalisPedisLeft === "pouls pédieux présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="dorsalisPedisLeft" value="pouls pédieux non perçu (G)" checked={formState.dorsalisPedisLeft === "pouls pédieux non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>
              </p>

              <p>Pouls tibial post G:  &ensp;
            
            <input type="radio" name="tibialPostLeft" value="pouls tibial post. présent (G)" checked={formState.tibialPostLeft === "pouls tibial post. présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="tibialPostLeft" value="pouls tibial post. non perçu (G)" checked={formState.tibialPostLeft === "pouls tibial post. non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>

              </p>

          
            <p>Tps recoloration G: &ensp; 
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration inférieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration inférieur à 3 secondes (G)"} onChange={handleInputChange}/>
              <label> {'>'} 3''</label>
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration supérieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration supérieur à 3 secondes (G)"} onChange={handleInputChange} />
              <label> {'<'} 3''' </label>
              </p>
         


             
          <p>Pouls pédieux D: &emsp;
            
            <input type="radio" name="dorsalisPedisRight" value="pouls pédieux présent (D)" checked={formState.dorsalisPedisRight === "pouls pédieux présent (D)"} onChange={handleInputChange} required/>
            <label> + </label>
        
            <input type="radio" name="dorsalisPedisRight" value="pouls pédieux non perçu (D)" checked={formState.dorsalisPedisRight === "pouls pédieux non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>


            <p>Pouls tibial post D: &ensp;
          
            <input type="radio" name="tibialPostRight" value="pouls tibial post. présent (D)" checked={formState.tibialPostRight === "pouls tibial post. présent (D)"} onChange={handleInputChange}required/>
            <label> + </label>
          
            <input type="radio" name="tibialPostRight" value="pouls tibial post. non perçu (D)" checked={formState.tibialPostRight === "pouls tibial post. non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>

            <p>Tps recoloration, D: &ensp;
            
              <input type="radio" name="refillTimeRight" value="temps de recoloration inférieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration inférieur à 3 secondes (D)"} onChange={handleInputChange}/>
              <label> {'>'} 3'' </label>
          
              <input type="radio" name="refillTimeRight" value="temps de recoloration supérieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration supérieur à 3 secondes (D)"} onChange={handleInputChange} />
              <label> {'<'} 3'' </label>

              </p>
          </div>
        </>
      )}
        
        <br></br>

      <div>
        <label className="title-label-within-card">Trouble sensitif:</label>
        <select className="select-input" name="neurologySensLowerLimb" value={formState.neurologySensLowerLimb} onChange={handleInputChange}>
          <option value="pas de déficit">aucun</option>
          <option value="Paresthésie">Paresthésie</option>
          <option value="Dysesthésie">Dysesthésie</option>
          <option value="Hypoesthésie">Hypoesthésie</option>
          <option value="Anesthésie">Anesthésie</option>
          <option value="AUTRE">AUTRE</option>

        </select>

        </div>


        {formState.neurologySensLowerLimb !== "pas de déficit" && (
        <>
          <div>

          <select className="select-input" name="neuroSensTerritoryLowerLimb" value={formState.neuroSensTerritoryLowerLimb} onChange={handleInputChange} >
            <option value="">territoire:</option>
            <option value="territoire L2">L2</option>
            <option value="territoire L3">L3</option>
            <option value="territoire L4">L4</option>
            <option value="territoire L5">L5</option>
            <option value="territoire S1">S1</option>
            <option value="territoire S2">S2</option>
            <option value="territoire n. fémoral">n. fémoral</option>
            <option value="territoire n. cutané fémoral">n. cutané fémoral</option>
            <option value="territoire branche infra-patellaire du saphène">branche infra-patellaire du saphène</option>
            <option value="territoire n. saphène">n. saphène</option>
            <option value="territoire n.cutané sural latéral">n.cutané sural latéral</option>
            <option value="territoire n. Sural">n. Sural</option>
            <option value="territoire n. fibulaire commun">n. fibulaire commun</option>
            <option value="territoire branche infra-patellaire du saphène">n. fibulaire superficiel</option>
            <option value="territoire n. fibulaire profond">n. fibulaire profond</option>
            <option value="territoire n. tibial">n. tibial</option>
            <option value="AUTRE">AUTRE</option>

            </select>

            <br></br>
         
            <input type="radio" name="neuroSensTerritorySideLowerLimb" value="gauche" checked={formState.neuroSensTerritorySideLowerLimb === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
          
            <input type="radio" name="neuroSensTerritorySideLowerLimb" value="droit" checked={formState.neuroSensTerritorySideLowerLimb === "droit"} onChange={handleInputChange} />
            <label> droit </label>

            <input type="radio" name="neuroSensTerritorySideLowerLimb" value="bilatéralement" checked={formState.neuroSensTerritorySideLowerLimb === "bilatéralement"} onChange={handleInputChange} />
            <label> bilat </label>
          </div>
         </>
      )}
     

<br></br>
    

      <div>
          <label className="title-label-within-card">Trouble moteur:</label>
          <select className="select-input" name="neurologyMotorLowerLimb" value={formState.neurologyMotorLowerLimb} onChange={handleInputChange} >
            <option value="pas de déficit">aucun</option>
            <option value="Parésie M4">Parésie, M4</option>
            <option value="Parésie M3">Parésie, M3</option>
            <option value="Parésie M2">Parésie, M2</option>
            <option value="Parésie M1">Parésie, M1</option>
            <option value="Plégie M0">Plégie, M0</option>
            <option value="AUTRE">AUTRE</option>

          </select>
     
        </div>
     

          {formState.neurologyMotorLowerLimb !== "pas de déficit" && (
        <>
        <div>
          <select className="select-input" name="neuroMotorTerritoryLowerLimb" value={formState.neuroMotorTerritoryLowerLimb} onChange={handleInputChange} >
              <option value="">-</option>
              <option value="flexion hanche">flexion hanche</option>
              <option value="extension hanche">extension hanche</option>
              <option value="Abduction hanche">Abduction hanche</option>
              <option value="Adduction hanche">Adduction hanche</option>
              <option value="flexion genou">flexion genou</option>
              <option value="extension genou">extension genou</option>
              <option value="flexion plantaire cheville">flexion plantaire cheville</option>
              <option value="flexion dorsale cheville">flexion dorsale cheville</option>
              <option value="inversion">inversion</option>
              <option value="éversion">éversion</option>
              <option value="AUTRE">AUTRE</option>

          </select>

          <br></br>
          
            <input type="radio" name="neuroMotorTerritorySideLowerLimb" value="côté gauche" checked={formState.neuroMotorTerritorySideLowerLimb === "côté gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="neuroMotorTerritorySideLowerLimb" value="côté droit" checked={formState.neuroMotorTerritorySideLowerLimb === "côté droit"} onChange={handleInputChange} />
            <label> droit </label>

            <input type="radio" name="neuroMotorTerritorySideLowerLimb" value="bilatéralement" checked={formState.neuroMotorTerritorySideLowerLimb === "bilatéralement"} onChange={handleInputChange} />
            <label> bilat </label>
           </div>
          
         </>
      )}

         
<br></br>


            <div>
                <select className="select-input" name="quadRfx" value={formState.quadRfx} onChange={handleInputChange} >
                  <option value="">Réflexes quadricipitaux:</option>
                  <option value="présents et symétriques">présents et symétriques</option>
                  <option value="absents">absents</option>
                  <option value="AUTRE">AUTRE</option>

                </select>
             
                <select className="select-input" name="achilleRfx" value={formState.achilleRfx} onChange={handleInputChange} >
                  <option value="">Réflexes achilléens:</option>
                  <option value="présents et symétriques">présents et symétriques</option>
                  <option value="absents">absents</option>
                  <option value="AUTRE">AUTRE</option>

                </select>
              </div>


      
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default NVstatusMI;