import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function HipRecon({setNameForm, onFormSubmit, onSelectionChange}) {
  const [formState, setFormState] = useState({
    sideHipRecon: '',
    stemType: '',
    collar: '',
    subsidence:'',
    stressShielding:'',
    Pedestal:'',
    periPFracture:'',
    boneLossFemur:'',
    
    cupType:'',
    boneLossAcetabulum:'',

    bearingType:'',
    bearingSurfaces:'',
    eccentration:'',

    lenghtRecon:'',
    offsetRecon:'',

  });

  const [HipReconXray, setHipReconXray] = useState({
    "Bassin de face":false,
    "Bassin calibré centré symphyse":false,
    "Lequesne": false,
    "Hanche face" :false,
    "Hanche axiale": false,
    "CT-scan bassin": false,
    "CT-scan hanche": false,
  
  });

  const [GruenZones, setGruenZones] = useState({
    "I":false,
    "II":false,
    "III": false,
    "IV": false,
    "V":false,
    "VI": false,
    "VII":false,  
  });

  const [CharnleyZones, setCharnleyZones] = useState({
    "I":false,
    "II":false,
    "III": false,
  
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const handleCheckboxChangeHipReconXray = (event) => {
    const { name, checked } = event.target;
     
      setHipReconXray(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };


  const handleCheckboxChangeGruenZones = (event) => {
    const { name, checked } = event.target;
     
      setGruenZones(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleCheckboxChangeCharnleyZones = (event) => {
    const { name, checked } = event.target;
     
      setCharnleyZones(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };





  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Hanche prothétique");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const HipReconXrayText = formatCheckboxStateToString(HipReconXray);
  const GruenZonesText = formatCheckboxStateToString(GruenZones);
  const CharnleyZonesText = formatCheckboxStateToString(CharnleyZones);
  


    // Prepare form details for summary
    const formDetails = {
      "Bilan radiologique": HipReconXrayText,
      "Arthroplastie totale de hanche du côté": formState.sideHipRecon,
      "tige fémorale": [formState.stemType, formState.collar, formState.subsidence, formState.stressShielding, formState.Pedestal]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      
      "liseré périprothétique en zone(s) de Gruen": GruenZonesText,
      "Fracture péri-prothétique fémorale selon Vancouver": formState.periPFracture,
      "Perte osseuse fémorale selon Paprovsky": formState.boneLossFemur,

      "Cupule": formState.cupType,
      "Liseré périprothétique en zone(s) de Charnley": CharnleyZonesText,
      "Perte osseuse acétabulaire selon Paprovsky": formState.boneLossAcetabulum,

      "couple de frottement": [formState.bearingType, formState.bearingSurfaces, formState.eccentration]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),
      
      "reconstruction longueur": formState.lenghtRecon ? `${formState.lenghtRecon} (mm)` : "",
      "reconstruction offset": formState.offsetRecon ? `${formState.offsetRecon} (mm)` : "",
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Hanche prothétique, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div className="checkbox-options">
    
          <div>
          <input type="radio" name="sideHipRecon" value="gauche" checked={formState.sideHipRecon === "gauche"} onChange={handleInputChange} />
          <label> gauche </label>
      
          <input type="radio" name="sideHipRecon" value="droit" checked={formState.sideHipRecon === "droit"} onChange={handleInputChange} />
          <label> droite </label>
          </div>
      </div>

      <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
         <div className="checkbox-options">
         <p className="description">Incidence radiologiques:</p>
         {Object.entries(HipReconXray).map(([key, value]) => (
           <div key={key}>
             <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeHipReconXray} />
             <label>{key}</label>
           </div>
         ))}
       </div>


   )}

 
    

      <br></br>

      <label className = "title-label-within-card" > Description tige fémorale et fémur proximal: </label>
      <br></br>
        

        <div>
         
          <select name="stemType" className="select-input" value={formState.stemType} onChange={handleInputChange} >
            <option value="">Type tige:</option>
            <option value="non-cimentée ">non-cimentée</option>
            <option value="non-cimentée, géométrie de type single-wedge ">non-cimentée, géométrie de type single-wedge</option>
            <option value="non-cimentée, géométrie de type double-wedge/quadrangular taper ">non-cimentée, géométrie de type double-wedge/quadrangular taper</option>
            <option value="non-cimentée, géométrie de type remplissage métaphysaire/anatomique ">non-cimentée, géométrie de type remplissage métaphysaire/anatomique</option>
            <option value="non-cimentée, géométrie de type conique ">non-cimentée, géométrie de type conique</option>
            <option value="non-cimentée, géométrie de type cylindrique ">non-cimentée, géométrie de type cylindrique</option>
            <option value="non cimentée, modulaire ">non cimentée, modulaire</option>
            <option value="on-cimentée, courte, calcar-guided ">non-cimentée, courte, calcar-guided</option>
            <option value="">-----</option>
            <option value="cimentée ">cimentée</option>
            <option value="cimentée, géométrie de type taper slip/force closed ">cimentée, géométrie de type taper slip/force closed</option>
            <option value="cimentée, géomtérie de type composite beams/shaped closed ">cimentée, géométrie de type composite beams/shaped closed</option>


          </select>
        </div>

             <div>
             <input type="radio" name="collar" value="sans collerette" checked={formState.collar === "sans collerette"} onChange={handleInputChange} />
            <label> sans collerette </label>
         
            <input type="radio" name="collar" value="avec collerette" checked={formState.collar === "avec collerette"} onChange={handleInputChange} />
            <label> avec collerette </label>
            </div>






            <div>
            <label> Subsidence: &emsp;&ensp;</label>
           <input type="radio" name="subsidence" value="pas de signe de subsidence" checked={formState.subsidence === "pas de signe de subsidence"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="subsidence" value="avec signe de subsidence" checked={formState.subsidence === "avec signe de subsidence"} onChange={handleInputChange} />
            <label> + </label>
            </div>


            <div>
            <label> Stressshielding: </label>
           <input type="radio" name="stressShielding" value="pas de signe de stressshielding" checked={formState.stressShielding === "pas de signe de stressshielding"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="stressShielding" value="signe de stressshielding" checked={formState.stressShielding === "signe de stressshielding"} onChange={handleInputChange} />
            <label> + </label>
            </div>

            <div>
            <label> Piédestal: &emsp; &emsp; </label>
           <input type="radio" name="Pedestal" value="absence de piedéstal" checked={formState.Pedestal === "absence de piedéstal"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="Pedestal" value="présence d'un piedéstal" checked={formState.Pedestal === "présence d'un piedéstal"} onChange={handleInputChange} />
            <label> + </label>
            </div>



            <div className="checkbox-options-specific-card">
        <p className="description">Liseré périprothétique en zone de Gruen:</p>
        {Object.entries(GruenZones).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeGruenZones} />
            <label>{key}</label>
          </div>
        ))}
      </div>



            <div>
         
         <select name="periPFracture" className="select-input" value={formState.periPFracture} onChange={handleInputChange} >
           <option value="">Fracture péri-prothétique fémur selon Vancouver:</option>
           <option value="Ag (grand trochanter)">Ag {'('}grand trochanter{')'}</option>
           <option value="Al (petit trochanter)">Al {'('}petit trochanter{')'}</option>
           <option value="B1 (au niveau de la tige, sans descellement)">B1 {'('}au niveau de la tige, sans descellement{')'}</option>
           <option value="B2 (au niveau de la tige, avec descellement) ">B2 {'('}au niveau de la tige, avec descellement{')'}</option>
           <option value="B3 (au niveau de la tige, avec descellement et mauvais stock osseux)">B3 {'('}au niveau de la tige, avec descellement et mauvais stock osseux{')'}</option>
           <option value="C (distal à la tige)">C {'('}distal à la tige{')'}</option>
 

         </select>
       
         
         <select name="boneLossFemur" className="select-input" value={formState.boneLossFemur} onChange={handleInputChange} >
           <option value="">Perte osseuse fémorale selon Paprovsky:</option>
           <option value="Type I (perte osseuse métaphysaire minime, métaphyse et diaphyse intactes)">Type I (perte osseuse métaphysaire minime, métaphyse et diaphyse intactes)</option>
           <option value="Type II (perte osseuse métaphysaire importante à sévère, diaphyse intacte)">Type II (perte osseuse métaphysaire importante à sévère, diaphyse intacte)</option>
           <option value="Type IIIa (perte osseuse métaphysaire importante, perte diaphysaire, diaphyse intacte sur >4cm depuis l'isthme)">Type IIIa (perte osseuse métaphysaire importante, perte diaphysaire, diaphyse intacte sur {'>'} 4cm depuis l'isthme</option>
           <option value="Type IIIb (perte osseuse mÈtaphysaire importante, perte diaphysaire, diaphyse intacte sur <4cm depuis l'isthme)">Type IIIa (perte osseuse métaphysaire importante, perte diaphysaire, diaphyse intacte sur {'<'} 4cm depuis l'isthme)</option>
           <option value="Type IV (perte complète métaphyse et diaphyse)">Type IV (perte complète métaphyse et diaphyse)</option>
    
 

         </select>
       </div>

       <br></br>


       <label className = "title-label-within-card" > Description composant acétabulaire: </label>
       <br></br>

       <div>
         
         <select name="cupType" className="select-input" value={formState.cupType} onChange={handleInputChange} >
           <option value="">Type:</option>
           <option value="cupule à tenue press-fit">cupule à tenue press-fit</option>
           <option value="cupule à tenue press-fit, avec vis supplémentaires">cupule à tenue press-fit, avec vis supplémentaires</option>
           <option value="cupule cimentée">cupule cimentée</option>
           <option value="Anneau de soutient, cupule cimentée">Anneau de soutient, cupule cimentée</option>
           <option value="Cupule de révision avec Shell/Augments">Cupule de révision avec Shell/Augments</option>
    
 

         </select>
       </div>

       <div className="checkbox-options-specific-card">
        <p className="description">Liseré périprothétique en zone de Charnley:</p>
        {Object.entries(CharnleyZones).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeCharnleyZones} />
            <label>{key}</label>
          </div>
        ))}
      </div>



    <div>
         
         <select name="boneLossAcetabulum" className="select-input" value={formState.boneLossAcetabulum} onChange={handleInputChange} >
           <option value="">Perte osseuse selon Paprovsky:</option>
           <option value="Type I: cotyle hémisphérique, perte osseuse minime, pas de migration">Type I: cotyle hémisphérique, perte osseuse minime, pas de migration</option>
           <option value="type II: perte sphéricité, colonnes intactes, migration de moins de 2cm supéro-médiale ou latérale, ostéolyse ischion et teardrop minime">type II: perte sphéricité, colonnes intactes, migration de moins de 2cm supéro-médiale ou latérale, ostéolyse ischion et teardrop minime</option>
           <option value="type IIA: avec migration supéro-médiale">type IIA: avec migration supéro-médiale</option>
           <option value="type IIB: avec migration supéro-latérale">type IIB: avec migration supéro-latérale</option>
           <option value="type IIC: avec migration médiale">type IIC: avec migration médiale</option>
           <option value="type III: migration supérieure à 2cm, ostéolyse médiale et ischion, perte soutient acétabulum">type III: migration supérieure à 2cm, ostéolyse médiale et ischion, perte soutient acétabulum</option>
           <option value="Type IIIa: Ligne de Köhler intact, migration supéro-latérale">Type IIIa: Ligne de Köhler intact, migration supéro-latérale</option>
           <option value="Type IIIB: perte de la ligne de Köhler, migration supéro-médiale">Type IIIB: perte de la ligne de Köhler, migration supéro-médiale</option>
    

         </select>
       </div>
       <br></br>


       <label className = "title-label-within-card"> Description couple de frottement: </label>
       <br></br>
      

    <div>
         
         <select name="bearingType" className="select-input" value={formState.bearingType} onChange={handleInputChange} >
           <option value="">Type articulation:</option>
           <option value="articulation simple mobilité">articulation simple mobilité</option>
           <option value="articulation simple mobilité, diamètre 28mm">articulation simple mobilité, diamètre 28mm</option>
           <option value="articulation simple mobilité, diamètre 32mm">articulation simple mobilité, diamètre 32mm</option>
           <option value="articulation simple mobilité, diamètre 36mm">articulation simple mobilité, diamètre 36mm</option>
           <option value="articulation simple mobilité, grand diamètre">articulation simple mobilité, grand diamètre</option>
           <option value="articulation double mobilité">articulation double mobilité</option>
    

         </select>
       
         
         <select name="bearingSurfaces" className="select-input" value={formState.bearingSurfaces} onChange={handleInputChange} >
           <option value="">Composant:</option>
           <option value="céramique-polyéthylène">céramique-polyethylène</option>
           <option value="métal-polyéthylène">métal-polyéthylène</option>
           <option value="céramique-céramique">céramique-céramique</option>
           <option value="métal-métal">métal-métal</option>


         </select>
       </div>

       <div>
            <label> Eccentration: &emsp; &emsp; </label>
           <input type="radio" name="eccentration" value="pas d'eccentration" checked={formState.eccentration === "pas d'eccentration"} onChange={handleInputChange} />
            <label> - </label>
         
            <input type="radio" name="eccentration" value="avec eccentration" checked={formState.eccentration === "avec eccentration"} onChange={handleInputChange} />
            <label> + </label>
            </div>

            <br></br>

            <label className = "title-label-within-card" > Mesures reconstruction: </label>
            <div>
          <input type="number" className="number-input" name="lenghtRecon" min="-50" max="*50" placeholder = "longueur (mm)" value={formState.lenghtRecon} onChange={handleInputChange} />
       
        
          <input type="number" className="number-input" name="offsetRecon" min="-50" max="*50" placeholder = "Offset (mm)" value={formState.offsetRecon} onChange={handleInputChange} />
       </div>


        
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default HipRecon;