import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function TibiaXRayTraumat({setNameForm, onFormSubmit, onSelectionChange}) {
  const [formState, setFormState] = useState({
    sideTibiaXRayTraumat: '',
    proxTibiaSchatzkerFracture:'',
    patellaFracture:'',
    tibialSpineFracture:'',
    tibialDiaphysisFracture:'',
    tibiaDistalFractures:'',
    malleolarFractures:''
  });

  const [TibiaXRayTraumat, setTibiaXRayTraumat] = useState({
    "Genou face":false,
    "Genou profile":false,
    "Jambe face":false,
    "Jambe profile":false,
    "Cheville face":false,
    "Cheville profile":false,
    "CT-scan": false,
  
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const handleCheckboxChangeTibiaXRayTraumat = (event) => {
    const { name, checked } = event.target;
     
      setTibiaXRayTraumat(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Tibia et péroné, traumatologie");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const TibiaXRayTraumatText = formatCheckboxStateToString(TibiaXRayTraumat);


    // Prepare form details for summary
    const formDetails = {
      "Du côté": formState.sideTibiaXRayTraumat,
      "Bilan radiologique par": TibiaXRayTraumatText,
      "Fracture tibia proximal": formState.proxTibiaSchatzkerFracture,
      "Fracture épines tibiales": formState.tibialSpineFracture,
      "Fracture diaphyse tibiale": formState.tibialDiaphysisFracture,
      "Fracture tibia distal": formState.tibiaDistalFractures,
      "Fracture malléolaire": formState.malleolarFractures

      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Tibia, péroné et rotule, traumatologie</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div className="checkbox-options">
          <p>Côté:  <input type="radio" name="sideTibiaXRayTraumat" value="gauche" checked={formState.sideTibiaXRayTraumat === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="sideTibiaXRayTraumat" value="droit" checked={formState.sideTibiaXRayTraumat === "droit"} onChange={handleInputChange} />
            <label> droit </label></p>
            <div>
           
            </div>
        </div>

        <br></br>

        <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
       <div className="checkbox-options">
       <p className="description">Incidence radiologiques:</p>
       {Object.entries(TibiaXRayTraumat).map(([key, value]) => (
         <div key={key}>
           <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeTibiaXRayTraumat} />
           <label>{key}</label>
         </div>
       ))}
     </div>


   )}

        
 
    

      <br></br>

      <div>
         
          <select name="patellaFracture" className="select-input" value={formState.patellaFracture} onChange={handleInputChange} >
    
            <option value="">Fracture patella:</option>
            <option value="transverse">transverse</option>
            <option value="verticale">verticale</option>
            <option value="pôle supérieur">pôle supérieur</option>
            <option value="pôle inférieur">pôle inférieur</option>
            <option value="AUTRE">AUTRE</option>
            
          </select>
       
        
          <select name="proxTibiaSchatzkerFracture" className="select-input" value={formState.proxTibiaSchatzkerFracture} onChange={handleInputChange} >
            <option value="">Fracture tibia proximal selon Schatzker:</option>
            <option value="Schatzker I (split plateau externe, pas de de dépression)">Schatzker I {'('} split plateau externe, pas de de dépression{')'}</option>
            <option value="Schatzker II (split et dépression plateau externe)">Schatzker II {'('} split et dépression plateau externe{')'}</option>
            <option value="Schatzker III (dépression plateau externe, sans split)">Schatzker III {'('}dépression plateau externe, sans split{')'}</option>
            <option value="Schatzker IV (split et/ou dépression plateau interne)">Schatzker IV {'('}split plateau interne{')'}</option>
            <option value="Schatzker V (atteinte plateau tibiaux interne et externe)">Schatzker V {'('}atteinte plateau tibiaux interne et externe{')'}</option>
            <option value="Schatzker VI (atteinte plateau tibial interne et externe associé à discontinuité métaphyso-diaphysaire)">Schatzker VI {'('}atteinte plateau tibial interne et{'/'}ou externe associé à discontinuité métaphyso-diaphysaire{')'}</option>
            <option value="AUTRE">AUTRE</option>
          
          </select>
       
          
          <select name="tibialSpineFracture" className="select-input" value={formState.tibialSpineFracture} onChange={handleInputChange} >
            <option value="">Fracture épines tibiales selon Meyers and McKeever:</option>
            <option value="non déplacée, Meyers and McKeever I">non déplacée, Meyers and McKeever I</option>
            <option value="partiellement déplacée, charnière postérieure conservée, Meyers and McKeever II">partiellement déplacée, charnière postérieure conservée, Meyers and McKeever II</option>
            <option value="avec déplacement complet, Meyers and McKeever III">avec déplacement complet, Meyers and McKeever III</option>
            <option value="avec déplacement complet et rotation, Meyers and McKeever III+">avec déplacement complet et rotation, Meyers and McKeever III+</option>
            <option value="déplacée et comminutive, Meyers and McKeever IV">déplacée et comminutive, Meyers and McKeever IV</option>
            <option value="AUTRE">AUTRE</option>
      
          </select>
       
        
          <select name="tibialDiaphysisFracture" className="select-input" value={formState.tibialDiaphysisFracture} onChange={handleInputChange} >
          <option value="">Fracture diaphyse tibiale selon l'AO:</option>
            <option value="spiroide (Classification AO 42-A1)">spiroide (Classification AO 42-A1)</option>
            <option value="oblique (Classification AO 42-A2)">oblique (Classification AO 42-A2)</option>
            <option value="transverse (Classification AO 42-A3)">transverse (Classification AO 42-A3)</option>
            <option value="type wedge (Classification AO 42-B2)">type wedge (Classification AO 42-B2)</option>
            <option value="type wedge, fragmentaire (Classification AO 42-B3)">type wedge, fragmentaire (Classification AO 42-B3)</option>
            <option value="segmentaire (Classification AO 42-C2)">segmentaire (Classification AO 42-C2)</option>
            <option value="segmentaire, plurifragmentaire (Classification AO 42-C3)">segmentaire, plurifragmentaire (Classification AO 42-C3)</option>
         
          </select>
       
         
          <select name="tibiaDistalFractures" className="select-input" value={formState.tibiaDistalFractures} onChange={handleInputChange} >
          <option value="">Fracture tibia distal selon l'AO:</option>
          <option value="spiroide, sans extension articulaire (Classification AO 43-A1)"> spiroide, sans extension articulaire (Classification AO 43-A1)</option>
            <option value="wedge, sans extension articulaire (Classification AO 42-A2)">wedge, sans extension articulaire (Classification AO 43-A2)</option>
            <option value="comminutive, extra-articulaire (Classification AO 42-A3)">comminutive, extra-articulaire (Classification AO 43-A3)</option>
            <option value="articulaire partielle (Classification AO 42-B)">articulaire partielle (Classification AO 43-B2)</option>
            <option value="type pilon tibial (Classification AO 43-C)">type wedge, fragmentaire (Classification AO 43-B3)</option>

          </select>
       

          <select name="malleolarFractures" className="select-input" value={formState.malleolarFractures} onChange={handleInputChange} >
          <option value="">Fractures malléolaires:</option>
           <option value="fracture spiroide péroné distal (supination-ER grade II selon Lauge-Hansen/ Weber B)"> fracture spiroide péroné distal (supination-ER grade II / Weber B)</option>
            <option value="fracture spiroide péroné distal et fracture malléole postérieure (supination-ER grade III selon Lauge-Hansen)">fracture spiroide péroné distal et fracture malléole postérieure (supination-ER grade III)</option>
            <option value="fractures spiroide péroné distal, fracture malléole postérieure et fracture malléole interne (supination-ER grade IV selon Lauge-Hansen)">fractures spiroide péroné distal, fracture malléole postérieure et fracture malléole interne (supination-ER grade IV)</option>
            <option value="">-----</option>
            <option value="fracture par avulsion péroné distal (supination-adduction grade I selon Lauge-Hansen / Weber A)">fracture par avulsion péroné distal (supination-adduction grade I / Weber A)</option>
            <option value="fracture par avulsion péroné distal et fracture oblique/verticale malléole interne (supination-adduction grade II selon Lauge-Hansen)">fracture par avulsion péroné distal et fracture oblique/verticale malléole interne (supination-adduction grade II selon Lauge-Hansen)</option>
            <option value="">-----</option>
            <option value="fracture par avulsion malléole interne (pronation-ER grade I selon Lauge-Hansen)">fracture par avulsion malléole interne (pronation-ER grade I)</option>
            <option value="diastase péronéo-tibial (pronation-ER grade II selon Lauge-Hansen)">diastase péronéo-tibial (pronation-ER grade II)</option>
            <option value="fracture supra-syndesmotique péroné distal (pronation-ER grade III selon Lauge-Hansen/Weber C)">fracture supra-syndesmotique péroné distal (pronation-ER grade III/Weber C)</option>
          </select>
        </div>


        
        
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default TibiaXRayTraumat;
