// Allergies.js
import React, { useState, useEffect } from 'react';
import '../../../../styles/app.css';

function ClinicalMass({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({
    sex:'XX',
    age:'Patient(e)',
    location:'',
    location_detail:'',
    side:'',
    duration:'',
    duration_unit:'',
    growth:'',
    
    systemic_symptoms:''
    
  });


  const [localSymptoms, setlocalSymptoms] = useState({
  
    "gêne esthétique": false,
    "effet de masse": false,
    "douleurs légères": false,
    "douleurs modérées": false,
    "douleurs importantes": false,
    "AUTRE": false
  
  });


  const handleCheckboxChangelocalSymptoms = (event) => {
    const { name, checked } = event.target;
     
      setlocalSymptoms(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const [systemicSymptoms, setsystemicSymptoms] = useState({
  
    "fièvre": false,
    "sudations nocturnes": false,
    "perte de poids": false,
    "fatigue": false,
    "inappétence": false,
    
  });


  const handleCheckboxChangesystemicSymptoms = (event) => {
    const { name, checked } = event.target;
     
      setsystemicSymptoms(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Anamnèse masse clinique');

    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || "aucun symptôme"; // If no options are selected, return "None selected"
  };

  const localSymptomsText = formatCheckboxStateToString(localSymptoms);
  const systemicSymptomsText = formatCheckboxStateToString(systemicSymptoms);



    const formDetails = {
     
      'L1': formState.sex + " de " + formState.age + " ans adressé(e) pour le bilan d'une masse localisée au niveau de " + formState.location + ", " + formState.location_detail + ", " + formState.side,
      'L2': "Cette dernière a été remarquée il y a " + formState.duration + " " + formState.duration_unit + ". La taille est " + formState.growth + " depuis.",
      
      'local symptoms': "Cette masse cause localement: " + localSymptomsText,
      'systemic symptoms': "Au niveau systémique: " + systemicSymptomsText
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}


  return (
    <div className="card">
      <p className="title">Masse clinique</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <div className="radio-options">
          
            <input type="radio" name="sex" value="Patiente" checked={formState.sex=== "Patiente"} onChange={handleInputChange}/>
            <label>♀</label>
         
            <input type="radio" name="sex" value="Patient" checked={formState.sex === "Patient"} onChange={handleInputChange} />
            <label> ♂ </label>

            <input type="number" className="number-input" name="age" min="0" max="120" placeholder= "age" value={formState.age} onChange={handleInputChange} />

          
            </div>




        <div>
          <select name="location" className="select-input" value={formState.location} onChange={handleInputChange}>
            <option value="">Localisation générale:</option>
            <option value="l'épaule">Epaule</option>
            <option value="du bras">Bras</option>
            <option value="du coude">Coude</option>
            <option value="de l'avant-bras">Avant-bras</option>
            <option value="du poignet">Poignet</option>
            <option value="">---</option>
            <option value="de la hanche">Hanche</option>
            <option value="de la cuisse">Cuisse</option>
            <option value="du genou">Genou</option>
            <option value="de la jambe">Jambe</option>
            <option value="de la cheville">Cheville</option>
            <option value="AUTRE">Autre</option>
          </select>
        
          <select name="location_detail" className="select-input" value={formState.location_detail} onChange={handleInputChange}>
            <option value="">Détail localisation</option>
            <option value="face antérieure">antérieure</option>
            <option value="face médiale">médiale</option>
            <option value="face postérieure">postérieure</option>
            <option value="face latérale">latérale</option>
            <option value="AUTRE">autre</option>
        
          </select>
        </div>

        <div>
         
         <input type="radio" name="side" value="côté gauche" checked={formState.side === "côté gauche"} onChange={handleInputChange} />
         <label> gauche </label>
        
           <input type="radio" name="side" value="côté droit" checked={formState.side === "côté droit"} onChange={handleInputChange} />
           <label> droite </label>
       </div>

       <div>
          
       


          <div className="radio-options">
          <label>Masse présente depuis:</label><br></br>
  
              <input type="number" className="number-input" name="duration" min="0" max="120" placeholder= "number" value={formState.duration} onChange={handleInputChange} />

              <select name="duration_unit" className="select-input-1-3" value={formState.duration_unit} onChange={handleInputChange}>
             <option value="">Unité:</option>
             <option value="semaines">semaines</option>
             <option value="mois">mois</option>
             <option value="année(s)">années</option>
             </select>
            
              </div>

        

              <select name="growth" className="select-input" value={formState.growth} onChange={handleInputChange}>
            <option value="">Croissance</option>
            <option value="stable, sans croissance notoire">taille stable</option>
            <option value="en croissance lente">croissance lente</option>
            <option value="en croissance marquée">croissance marquée</option>
            <option value="AUTRE">autre</option>
        
          </select>

          <div className="checkbox-options">
        <p className="title-label-within-card">Symptômes locaux:</p>
        {Object.entries(localSymptoms).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangelocalSymptoms} />
            <label>{key}</label>
          </div>
        ))}
      </div>

      <div className="checkbox-options">
        <p className="title-label-within-card">Symptômes systémiques:</p>
        {Object.entries(systemicSymptoms).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangesystemicSymptoms} />
            <label>{key}</label>
          </div>
        ))}
      </div>
        


          </div>

          

   

       

        


        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default ClinicalMass;