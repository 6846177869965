// Allergies.js
import React, { useState, useEffect } from 'react';
import '../../../../styles/app.css';

function ChronicConditions({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({
    Hypertension:'',
    Diabetes: '',
    Hypercholesterol: '',
    AtrialFibrillation: '',
    Asthma: '',
    BPCO: '',
    
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNameForm('Patient Allergy Information');


    const formDetails = {
      'Asthme': formState.Asthma,
      'HTA': formState.Hypertension,
      'HCL': formState.Hypercholesterol,
      'BPCO': formState.BPCO,
      'AtrialFibrillation': formState.AtrialFibrillation,
      'Diabetes': formState.Diabetes
     
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}


  return (
    <div className="card">
      <p className="title">Co-morbidités</p>
      <form className="specific-form" onSubmit={handleSubmit}>

      <label className = "title-label-within-card" > Cardiaque: </label>

        <div>
          <select name="Hypertension" className="select-input" value={formState.Hypertension} onChange={handleInputChange}>
            <option value="">Hypertension:</option>
            <option value="Hypertension artérielle">Hypertension artérielle</option>
            <option value="Hypertension artérielle traitée">Hypertension artérielle traitée</option>
            <option value="Hypertension artérielle non traitée">Hypertension artérielle non traitée</option>
          </select>
        
          <select name="AtrialFibrillation" className="select-input" value={formState.AtrialFibrillation} onChange={handleInputChange}>
            <option value="">FA:</option>
            <option value="Fibrillation auriculaire">Fibrillation auriculaire</option>
            <option value="Fibrillation auriculaire, sous anti-coagulation">Fibrillation auriculaire, sous anti-coagulation</option>
        
          </select>
        </div>

      

        <label className = "title-label-within-card" > Respiratoire: </label>

        <div>
          <select name="Asthma" className="select-input" value={formState.Asthma} onChange={handleInputChange}>
            <option value="">Asthme:</option>
            <option value="Asthme allergique">Asthme allergique</option>
            <option value="Asthme d'effort">Asthme d'effort</option>
            <option value="Asthme mixte">Asthme mixte</option>
        
          </select>
       
          <select name="BPCO" className="select-input" value={formState.BPCO} onChange={handleInputChange}>
            <option value="">BPCO:</option>
            <option value="BPCO non stadée">BPCO non stadée</option>
            <option value="BPCO, Stade I">BPCO, Stade I</option>
            <option value="BPCO, Stade II">BPCO, Stade II</option>
            <option value="BPCO, Stade III">BPCO, Stade III</option>
            <option value="BPCO, Stade IV">BPCO, Stade IV</option>
        
          </select>
        </div>
        
       

        <label className = "title-label-within-card" > Métabolique: </label>

        <div>
          <select name="Diabetes" className="select-input" value={formState.Diabetes} onChange={handleInputChange} >
            <option value="">Diabète:</option>
            <option value="Diabète de type I">Diabète de type I</option>
            <option value="Diabète de type II, non traité">Diabète de type II, non traité</option>
            <option value="Diabète de type II, traitement oral">Diabète de type II, traitement oral</option>
            <option value="Diabète de type II insulino-requérant">Diabète de type II insulino-requérant</option>
          </select>
        
          <select name="Hypercholesterol" className="select-input" value={formState.Hypercholesterol} onChange={handleInputChange} >
            <option value="">HCL:</option>
            <option value="Hypercholestérolémie">Hypercholestérolémie</option>
            <option value="Hypercholestérolémie traitée">Hypercholestérolémie traitée</option>
          
          </select>
        </div>

        


        

        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default ChronicConditions;