import React, { useEffect, useState } from "react";
import "../../../../styles/app.css";
import Modal from "../../../../other-components/Modal.js";

function XrayOncoLowerLimb({setNameForm, onFormSubmit, onSelectionChange}) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [specificImage, setSpecificImage] = useState("");

  const [formState, setFormState] = useState({
    side:'',
    Location:'',
    Location_Detail:'',
    Location_Radial:'',
    Region_Type:'',
    Delimitation:'',
    Matrix:'',
    Periosteal_Reaction:'',
    Expansion:''


  });

  const [XrayOnco, setXrayOnco] = useState({
    "Bassin de face":false,
    "Bassin calibré centré symphyse":false,
    "Hanche face" :false,
    "Hanche axiale": false,
    "Fémur face":false,
    "Fémur profil":false,
    "Genou face": false,
    "Genou profil":false,
    "Jambe face": false,
    "Jambe profil": false,
    "Cheville face": false,
    "Cheville profil": false
  
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const openModalWithSpecificImage = (image) => {
    setSpecificImage(image);
    setIsModalOpen(true);
  }

  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }



  const handleCheckboxChangeXrayOnco = (event) => {
    const { name, checked } = event.target;
     
      setXrayOnco(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };





  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Description tumorale, membre inférieur");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const XrayOncoText = formatCheckboxStateToString(XrayOnco);

  
  






  


    // Prepare form details for summary
    const formDetails = {
      "Localisation": [formState.Location, formState.Location_Detail, formState.Location_Radial, formState.side].filter(function(val) {
        return val;
      }).join(', '),

      "Bilan radiologique par": XrayOncoText,
      
      "Présence d'une région": [formState.Region_Type, formState.Delimitation, formState.Matrix, formState.Periosteal_Reaction, formState.Expansion].filter(function(val) {
        return val;
      }).join(', '),

  
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };






  return (
    <div className="card">
      <p className="title">Description tumorale, membre inférieur</p>
      <form className="specific-form" onSubmit={handleSubmit}>

     
        <div>
   
   <input
       type="checkbox"
       name="showXray"
       onChange={handleChangeshowXray}
       
     />
     <label>Renseigner incidences radiologiques</label>

   </div>
   

   {showXray && (
      <div className="checkbox-options">
      <p className="description">Incidence radiologiques:</p>
      {Object.entries(XrayOnco).map(([key, value]) => (
        <div key={key}>
          <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangeXrayOnco} />
          <label>{key}</label>
        </div>
      ))}
    </div>
     


   )}
 
    


    <div>
         
         <select name="Location" className="select-input" value={formState.Location} onChange={handleInputChange} >
           <option value="">Localisation:</option>
           <option value="ilium">ilium</option>
            <option value="ischium">ischium</option>
            <option value="pubis">pubis</option>
            <option value="">---</option>
            <option value="fémur">fémur</option>
            <option value="tibia">tibia</option>
            <option value="fibula">fibula</option>
            <option value="rotule">rotule</option>
         </select>

         <select name="Location_Detail" className="select-input" value={formState.Location_Detail} onChange={handleInputChange} >
           <option value="">Région:</option>
           <option value="portion proximale">portion proximale</option>
            <option value="diaphysaire">portion diaphysaire</option>
            <option value="portion distale">portion distale</option>
            <option value="">---</option>
            <option value="épiphyse proximale">épiphyse proximale</option>
            <option value="jonction épiphyso-métaphysaire proximale">jonction épiphyso-métaphysaire proximale</option>
            <option value="diaphyse proximal">diaphyse proximal</option>
            <option value="médio-diaphysaire">médio-diaphysaire</option>
            <option value="diaphyse distale">diaphyse distale</option>
            <option value="jonction épiphyso-métaphysaire distale">jonction épiphyso-métaphysaire distale</option>
            <option value="épiphyse distale">épiphyse distale</option>
         </select>

         <select name="Location_Radial" className="select-input" value={formState.Location_Radial} onChange={handleInputChange} >
           <option value="">localisation radiale:</option>
           <option value="intra-médullaire">intra-médullaire</option>
            <option value="endostée">endostée</option>
            <option value="intra-corticale">intra-corticale</option>
            <option value="périostéale">périostéale</option>
         </select>

         <div className="checkbox-options">
    
    <div>
    <input type="radio" name="side" value="côté gauche" checked={formState.side === "côté gauche"} onChange={handleInputChange} />
    <label> gauche </label>

    <input type="radio" name="side" value="côté droit" checked={formState.side === "côté droit"} onChange={handleInputChange} />
    <label> droite </label>
    </div>
</div>


       </div>

       <div>




        <label>Caractéristiques:</label>
         <select name="Region_Type" className="select-input" value={formState.Region_Type} onChange={handleInputChange} >
           <option value="">Type lésion:</option>
           <option value="ostéolytique">lytique</option>
            <option value="ostéoblastique">blastique</option>
            <option value="mixte">mixte</option>
         </select>

         <select name="Delimitation" className="select-input" value={formState.Delimitation} onChange={handleInputChange} >
           <option value="">Délimitation:</option>
           <option value="délimitation géographique, marges fines et sclérotiques">géographique, marges fines et sclérotiques</option>
            <option value="délimitation géographique, marges bien délimitées non sclerotiques">géographique, marges bien délimitées non sclerotiques</option>
            <option value="délimitation géographique, marges mal définies">géographique, marges mal définies</option>
            <option value="mal délimitée, 'moth eaten'">{'"'}moth eaten{'"'}</option>
            <option value="mal délimitée, perméative">perméative</option>
         </select>


         <select name="Matrix" className="select-input" value={formState.Matrix} onChange={handleInputChange} >
           <option value="">Matrice:</option>
           <option value="matrice fibreuse (verre dépoli)">fibreuse {'("'}verre dépoli{'")'}</option>
            <option value="matrice chondroïde (rings & arcs)">chondroïde {'("'}rings & arcs{'")'}</option>
            <option value="matrice ostéoïde (cumulus clouds)">ostéoïde {'("'}cumulus clouds{'")'}</option>
            <option value="pas de matrice visible">pas de matrice visible</option>
         </select>


         <select name="Periosteal_Reaction" className="select-input" value={formState.Periosteal_Reaction} onChange={handleInputChange} >
           <option value="">Réaction périostée:</option>
           <option value="pas de réaction périostée">aucune</option>
            <option value="réaction périostée unilamellaire">unilamellaire</option>
            <option value="réaction périostée multilamellaire">multilamellaire</option>
            <option value="réaction périostée solide">solide</option>
            <option value="réaction périostée spiculée">spiculée</option>
            <option value="réaction périostée complexe/désorganisée">complexe/désorganisée</option>
            <option value="réaction périostée avec triangle de Codeman">triangle de Codeman</option>
         </select>

         <select name="Expansion" className="select-input" value={formState.Expansion} onChange={handleInputChange} >
           <option value="">Expansion corticale:</option>
           <option value="absence d'expansion corticale">absente</option>
            <option value="présence d'une expansion corticale">présente</option>
         
         </select>

       </div>



        
        <button type="submit" className="button send">Send</button>
      </form>

      <br></br>

     
     



   

      {isModalOpen && ( /* If isModalOpen is true, <Modal> will be displayed. */
        <Modal imageSrc={specificImage} onClose={() => setIsModalOpen(false)} />
      )}
      
      
    </div>
  );
}

export default XrayOncoLowerLimb;