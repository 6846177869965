import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function NVstatusMS({ setNameForm, onFormSubmit, onSelectionChange }) {
  const [formState, setFormState] = useState({
    vascularUpperLimb:"pouls périphériques palpés, temps de recoloration inférieur à 3 secondes",
    RadialLeft: "",
    UlnarisLeft: "",
    refillTimeLeft: "",
    RadialRight: "",
    UlnarisRight: "",
    refillTimeRight: "",

    neurologySensUpperLimb: "pas de déficit",
    neuroSensTerritoryUpperLimb: "",
    neuroSensTerritorySideUpperLimb: "",

    neurologyMotorUpperLimb: "pas de déficit",
    neuroMotorTerritoryUpperLimb: "",
    neuroMotorTerritorySideUpperLimb: "",

    BicipitalRfx:"",
    TricpitalRfx:"",
    BrachioradialisRfx:"",

  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []); 

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Physical exam [Wrist]");
    // Here is where you would prepare the object with the form details to send to the onFormSubmit function
    const formDetails = {
      "Status vasculaire":[formState.vascularUpperLimb, formState.RadialLeft, formState.UlnarisLeft, formState.refillTimeLeft, formState.RadialRight, formState.UlnarisRight, formState.refillTimeRight].filter(function(val) {
        return val;
      }).join('; '),

     

      "Status sensitif":[formState.neurologySensUpperLimb,formState.neuroSensTerritoryUpperLimb, formState.neuroSensTerritorySideUpperLimb].filter(function(val) {
        return val;
      }).join(' '),

  

      "Status moteur": [formState.neurologyMotorUpperLimb, formState.neuroMotorTerritoryUpperLimb, formState.neuroMotorTerritorySideUpperLimb].filter(function(val) {
        return val;
      }).join(', '),

    

      "Réflexe bicipitaux": formState.BicipitalRfx,
      "Réflexe tricpitaux": formState.TricpitalRfx,
      "Réflexe Brachio-radialis": formState.BrachioradialisRfx
 

    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object


    onFormSubmit(filteredFormDetails); // Make sure this prop is passed correctly from App.js
  }

  return (
    <div className="card">
      <p className="title">Status neuro-vasculaire membres supérieurs</p>
      <form className="specific-form" onSubmit={handleSubmit}>
        
      <div>
        <label className="title-label-within-card">Trouble vasculaire:</label>
        <select className="select-input" name="vascularUpperLimb" value={formState.vascularUpperLimb} onChange={handleInputChange} >
          <option value="pouls périphériques palpés, temps de recoloration inférieur à 3 secondes">aucun</option>
          <option value="trouble vasculaire côté gauche">trouble vasculaire côté gauche</option>
          <option value="trouble vasculaire côté droit">trouble vasculaire côté droit</option>
          <option value="trouble vasculaire bilatéraux">troubles vasculaires bilatéraux</option>
      
        </select>
      </div>

  

      {formState.vascularUpperLimb === "trouble vasculaire côté gauche" && (
        <>
          <div> 
            <p>Pouls radial G:  &emsp;
            
            <input type="radio" name="RadialLeft" value="pouls radial présent (G)" checked={formState.RadialLeft === "pouls radial présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="RadialLeft" value="pouls radial non perçu (G)" checked={formState.RadialLeft === "pouls radial non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>
              </p>

              <p>Pouls ulnaire G:  &ensp;
            
            <input type="radio" name="UlnarisLeft" value="pouls ulnaire présent (G)" checked={formState.UlnarisLeft === "pouls ulnaire présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="UlnarisLeft" value="pouls ulnaire non perçu (G)" checked={formState.UlnarisLeft === "pouls ulnaire non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>

              </p>

          
            <p>Tps recoloration G: &ensp; 
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration inférieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration inférieur à 3 secondes (G)"} onChange={handleInputChange}/>
              <label> {'>'} 3''</label>
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration supérieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration supérieur à 3 secondes (G)"} onChange={handleInputChange} />
              <label> {'<'} 3''' </label>
              </p>
          </div>
        </>
      )}

      {formState.vascularUpperLimb === "trouble vasculaire côté droit" && (
        <>
          <div> 
          <p>Pouls radial D: &emsp;
            
            <input type="radio" name="RadialRight" value="pouls radial présent (D)" checked={formState.RadialRight === "pouls radial présent (D)"} onChange={handleInputChange} required/>
            <label> + </label>
        
            <input type="radio" name="RadialRight" value="pouls radial non perçu (D)" checked={formState.RadialRight === "pouls radial non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>


            <p>Pouls ulnaire D: &ensp;
          
            <input type="radio" name="UlnarisRight" value="pouls ulnaire présent (D)" checked={formState.UlnarisRight === "pouls ulnaire présent (D)"} onChange={handleInputChange}required/>
            <label> + </label>
          
            <input type="radio" name="UlnarisRight" value="pouls ulnaire non perçu (D)" checked={formState.UlnarisRight === "pouls ulnaire non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>

            <p>Tps recoloration, D: &ensp;
            
              <input type="radio" name="refillTimeRight" value="temps de recoloration inférieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration inférieur à 3 secondes (D)"} onChange={handleInputChange}/>
              <label> {'>'} 3'' </label>
          
              <input type="radio" name="refillTimeRight" value="temps de recoloration supérieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration supérieur à 3 secondes (D)"} onChange={handleInputChange} />
              <label> {'<'} 3'' </label>

              </p>
          </div>
        </>
      )}
        
      {formState.vascularUpperLimb === "trouble vasculaire bilatéraux" && (
        <>
           <div> 
           <p>Pouls radial:  &emsp;
            
            <input type="radio" name="RadialLeft" value="pouls radial présent (G)" checked={formState.RadialLeft === "pouls radial présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="RadialLeft" value="pouls radial non perçu (G)" checked={formState.RadialLeft === "pouls radial non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>
              </p>

              <p>Pouls ulnaire:  &ensp;
            
            <input type="radio" name="UlnarisLeft" value="pouls ulnaire présent (G)" checked={formState.UlnarisLeft === "pouls ulnaire présent (G)"} onChange={handleInputChange} required/>
            <label> + </label>
          
              <input type="radio" name="UlnarisLeft" value="pouls ulnaire non perçu (G)" checked={formState.UlnarisLeft === "pouls ulnaire non perçu (G)"} onChange={handleInputChange} required/>
              <label> - </label>

              </p>

          
            <p>Tps recoloration G: &ensp; 
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration inférieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration inférieur à 3 secondes (G)"} onChange={handleInputChange}/>
              <label> {'>'} 3''</label>
            
              <input type="radio" name="refillTimeLeft" value="temps de recoloration supérieur à 3 secondes (G)" checked={formState.refillTimeLeft === "temps de recoloration supérieur à 3 secondes (G)"} onChange={handleInputChange} />
              <label> {'<'} 3''' </label>
              </p>
         


             
          <p>Pouls radial D: &emsp;
            
            <input type="radio" name="RadialRight" value="pouls radial présent (D)" checked={formState.RadialRight === "pouls radial présent (D)"} onChange={handleInputChange} required/>
            <label> + </label>
        
            <input type="radio" name="RadialRight" value="pouls radial non perçu (D)" checked={formState.RadialRight === "pouls radial non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>


            <p>Pouls ulnaire D: &ensp;
          
            <input type="radio" name="UlnarisRight" value="pouls ulnaire présent (D)" checked={formState.UlnarisRight === "pouls ulnaire présent (D)"} onChange={handleInputChange}required/>
            <label> + </label>
          
            <input type="radio" name="UlnarisRight" value="pouls ulnaire non perçu (D)" checked={formState.UlnarisRight === "pouls ulnaire non perçu (D)"} onChange={handleInputChange} required/>
            <label> - </label>
            </p>

            <p>Tps recoloration, D: &ensp;
            
              <input type="radio" name="refillTimeRight" value="temps de recoloration inférieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration inférieur à 3 secondes (D)"} onChange={handleInputChange}/>
              <label> {'>'} 3'' </label>
          
              <input type="radio" name="refillTimeRight" value="temps de recoloration supérieur à 3 secondes (D)" checked={formState.refillTimeRight === "temps de recoloration supérieur à 3 secondes (D)"} onChange={handleInputChange} />
              <label> {'<'} 3'' </label>

              </p>
          </div>
        </>
      )}
        
        <br></br>

      <div>
        <label className="title-label-within-card">Trouble sensitif:</label>
        <select className="select-input" name="neurologySensUpperLimb" value={formState.neurologySensUpperLimb} onChange={handleInputChange}>
          <option value="pas de déficit">aucun</option>
          <option value="Paresthésie">Paresthésie</option>
          <option value="Dysesthésie">Dysesthésie</option>
          <option value="Hypoesthésie">Hypoesthésie</option>
          <option value="Anesthésie">Anesthésie</option>
          <option value="AUTRE">AUTRE</option>

        </select>

        {formState.neurologySensUpperLimb !== "pas de déficit" && (
        <>
          <div>

          <select className="select-input" name="neuroSensTerritoryUpperLimb" value={formState.neuroSensTerritoryUpperLimb} onChange={handleInputChange} >
            <option value="">territoire:</option>
            <option value="territoire C5">C5</option>
            <option value="territoire L3">C6</option>
            <option value="territoire C6">C7</option>
            <option value="territoire C8">C8</option>
            <option value="territoire T1">T1</option>
            <option value="territoire n. axillaire">n. axillaire</option>
            <option value="territoire n.radial">n.radial</option>
            <option value="territoire n. médian">n. médian</option>
            <option value="territoire n. ulnaire">n. ulnaire</option>
            <option value="territoire n. musculo-cutané">n. musculo-cutané</option>
            <option value="AUTRE">AUTRE</option>

            </select>

            <div>
         
            <input type="radio" name="neuroSensTerritorySideUpperLimb" value="gauche" checked={formState.neuroSensTerritorySideUpperLimb === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
          
            <input type="radio" name="neuroSensTerritorySideUpperLimb" value="droit" checked={formState.neuroSensTerritorySideUpperLimb === "droit"} onChange={handleInputChange} />
            <label> droit </label>

            <input type="radio" name="neuroSensTerritorySideUpperLimb" value="bilatéralement" checked={formState.neuroSensTerritorySideUpperLimb === "bilatéralement"} onChange={handleInputChange} />
            <label> bilat </label>
          </div>
          </div>
         </>
      )}

        </div>


        
     

<br></br>
    

      <div>
          <label className="title-label-within-card">Trouble moteur:</label>
          <select className="select-input" name="neurologyMotorUpperLimb" value={formState.neurologyMotorUpperLimb} onChange={handleInputChange} >
            <option value="pas de déficit">aucun</option>
            <option value="Parésie M4">Parésie, M4</option>
            <option value="Parésie M3">Parésie, M3</option>
            <option value="Parésie M2">Parésie, M2</option>
            <option value="Parésie M1">Parésie, M1</option>
            <option value="Plégie M0">Plégie, M0</option>
            <option value="AUTRE">AUTRE</option>

          </select>
     
        </div>
     

          {formState.neurologyMotorUpperLimb !== "pas de déficit" && (
        <>
        <div>
          <select className="select-input" name="neuroMotorTerritoryUpperLimb" value={formState.neuroMotorTerritoryUpperLimb} onChange={handleInputChange} >
              <option value="">-</option>
              <option value="abduction épaule">abduction épaule</option>
              <option value="RE épaule">RE épaule</option>
              <option value="RI épaule">RI épaule</option>
              <option value="flexion coude">flexion coude</option>
              <option value="extension coude">extension coude</option>
              <option value="flexion poignet">flexion poignet</option>
              <option value="extension poignet">extension poignet</option>
          
              <option value="AUTRE">AUTRE</option>

          </select>

          <br></br>
          
            <input type="radio" name="neuroMotorTerritorySideUpperLimb" value="côté gauche" checked={formState.neuroMotorTerritorySideUpperLimb === "côté gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="neuroMotorTerritorySideUpperLimb" value="côté droit" checked={formState.neuroMotorTerritorySideUpperLimb === "côté droit"} onChange={handleInputChange} />
            <label> droit </label>

            <input type="radio" name="neuroMotorTerritorySideUpperLimb" value="bilatéralement" checked={formState.neuroMotorTerritorySideUpperLimb === "bilatéralement"} onChange={handleInputChange} />
            <label> bilat </label>
           </div>
          
         </>
      )}

         
<br></br>


            <div>
                <select className="select-input" name="BicipitalRfx" value={formState.BicipitalRfx} onChange={handleInputChange} >
                  <option value="">Réflexes bicipitaux:</option>
                  <option value="présents et symétriques">présents et symétriques</option>
                  <option value="absents">absents</option>
                  <option value="AUTRE">AUTRE</option>

                </select>
             
                <select className="select-input" name="TricpitalRfx" value={formState.TricpitalRfx} onChange={handleInputChange} >
                  <option value="">Réflexes tricpitaux:</option>
                  <option value="présents et symétriques">présents et symétriques</option>
                  <option value="absents">absents</option>
                  <option value="AUTRE">AUTRE</option>

                </select>

                <select className="select-input" name="BrachioradialisRfx" value={formState.BrachioradialisRfx} onChange={handleInputChange} >
                  <option value="">Réflexes brachioradialis:</option>
                  <option value="présents et symétriques">présents et symétriques</option>
                  <option value="absents">absents</option>
                  <option value="AUTRE">AUTRE</option>

                </select>
              </div>


      
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default NVstatusMS;