import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function Knee({setNameForm, onFormSubmit, onSelectionChange}) { 
  const [formState, setFormState] = useState({
    SideKnee: "",
    kneeSkin: "pas de plaie, pas de cicatrice",
    kneeScar:"",
    kneeEffusion: "",
    kneeExtensorMechanism: "",
    kneeMedialStability: "",
    kneeLateralStability: "",
    kneeLachman:"",
    kneeAnteriorDrawer:"",
    kneePosteriorDrawer:"",
    kneePivotShift:"",

    McMurrayTest:"",
    GrindingTest:"",
    PatellarTracking:"",
    RotationStability:"",


    kneeFlexion:"",
    kneeNeutral:"",
    kneeExtension:""
  });

  const defaultValues = {
    kneeFlexion: 135,
    kneeNeutral: 0,
    kneeExtension: 5
  };


  const [kneePalpation, setkneePalpation] = useState({
    "interligne interne":false,
    "interligne externe":false,
    "mobilisation fémoro-patellaire": false,
    "rotule": false,
    "tubérosité tibiale antérieure": false,
    "trajet collatéral médial": false,
    "trajet collatéral latéral": false,
    "tendon quadricipital": false,
    "tendon rotulien": false,
    "AUTRE": false
  
  });

  const [TNVbasic, setTNVbasic] = useState({
    "absence de trouble neuro-vasculaire": false,
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);

  const handleCheckboxChangekneePalpation = (event) => {
    const { name, checked } = event.target;
     
      setkneePalpation(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };
  const handleCheckboxChangeTNVbasic = (event) => {
    const { name, checked } = event.target;
     
      setTNVbasic(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Physical exam [Knee]");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const kneePalpationText = formatCheckboxStateToString(kneePalpation);
  const TNVbasicText= formatCheckboxStateToString(TNVbasic);


    // Prepare form details for summary
    const formDetails = {
      "Examen clinique genou": formState.SideKnee,

      "Etat cutané": [ formState.kneeSkin, formState.kneeScar]
      .filter(val => val !== "" && val !== null && val !== undefined)
      .join(', '),

      "Status N-V": TNVbasicText,
      "Epanchement intra-articulaire": formState.kneeEffusion,
      "Appareil extenseur": formState.kneeExtensorMechanism,
      "Site(s) douloureux à la palpation": kneePalpationText,

      "Flexion/Extension": [formState.kneeFlexion, formState.kneeNeutral, formState.kneeExtension]
        .filter((val) => val !== "" && val !== null && val !== undefined)
        .join('/'),
    

      "Stabilité compartiment médial": formState.kneeMedialStability,
      "Stabilité compartiment latéral": formState.kneeLateralStability,
      "Test de Lachman": formState.kneeLachman,
      "Tiroir antérieur": formState.kneeAnteriorDrawer,
      "Tiroir postérieur": formState.kneePosteriorDrawer,
      "PivotShift": formState.kneePivotShift,
      "McMurray": formState.McMurrayTest,
      "Grinding test": formState.GrindingTest,
      "Tracking rotulien": formState.PatellarTracking,
      "Stabilité rotatoire": formState.RotationStability
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSetDefaultValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      ...defaultValues
    }));
  };

  return (
    <div className="card">
      <p className="title">Examen clinique genou</p>
      <form className="specific-form" onSubmit={handleSubmit}>
      <div>
          <label>Côté:  </label>
          <input type="radio" name="SideKnee" value="gauche" checked={formState.SideKnee === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="SideKnee" value="droit" checked={formState.SideKnee === "droit"} onChange={handleInputChange} />
            <label> droit </label>
        </div>

        <div> 

        <label>Etat cutané:</label>
          <select name="kneeSkin" className="select-input"  value={formState.kneeSkin} onChange={handleInputChange} >
            <option value="pas de plaie, pas de cicatrice">pas de plaie, pas de cicatrice</option>
            <option value="cicatrices d'abord arthroscopique">Cicactrice d'abord arthroscopique</option>
            <option value="cicatrice d'abord médian">cicatrice d'abord médian</option>
            <option value="cicatrice d'abord antéro-médial">cicatrice d'abord antéro-médial</option>
            <option value="cicatrice d'abord antéro-latéral">cicatrice d'abord antéro-latéral</option>
            <option value="AUTRE">AUTRE</option>
          </select>
        
        </div>

        {formState.kneeSkin !== "pas de plaie, pas de cicatrice" && (
           <div>
              <select name="kneeScar" className="select-input" value={formState.kneeScar} onChange={handleInputChange} >
          <option value="calme, sans signe infectieux">calme, sans signe infectieux</option>
          <option value="inflammatoire">inflammatoire</option>
          <option value="avec signes infectieux (écoulement, rougeur)">signes infectieux (écoulement, rougeur)</option>
          <option value="chéloïde">chéloïde</option>
          <option value="AUTRE">AUTRE</option>
        </select>
         
         </div>
      )
    }

      <div className="checkbox-options">
        
            <input type="checkbox" name="absence de trouble neuro-vasculaire"  onChange={handleCheckboxChangeTNVbasic} />
            <label>absence de trouble neuro-vasculaire</label>
          
      </div>
  
      

        <div className="checkbox-options">
        <p className="title-label-within-card">Douleurs à la palpation:</p>
        {Object.entries(kneePalpation).map(([key, value]) => (
          <div key={key}>
            <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangekneePalpation} />
            <label>{key}</label>
          </div>
        ))}
      </div>


      <div> 
          <p>Epanchement intra-articulaire: </p>
          <div>
            <input type="radio" name="kneeEffusion" value="absent" checked={formState.kneeEffusion === "absent"} onChange={handleInputChange} />
            <label> absent </label>
          
            <input type="radio" name="kneeEffusion" value="présent" checked={formState.kneeEffusion === "présent"} onChange={handleInputChange}/>
            <label> présent </label>
          </div>
        </div>
      

      <div className="radio-options">
          <p>Appareil extenseur:</p>
          <div>
            <input type="radio" name="kneeExtensorMechanism" value="compétent contre résistance" checked={formState.kneeExtensorMechanism === "compétent contre résistance"} onChange={handleInputChange}/>
            <label>compétent</label>
         
            <input type="radio" name="kneeExtensorMechanism" value="incompétent" checked={formState.kneeExtensorMechanism === "incompétent"} onChange={handleInputChange} />
            <label> incompétent </label>
          </div>
         
       <div>
        
      
        <label className="title-label-within-card">Amplitudes articulaire: <br></br></label>
          <br></br>
          <div>
           <button type="button" className="button-default-values" onClick={handleSetDefaultValues}>Partir des valeurs par défaut </button>
           </div>
           
        </div> 

        <div>
          <label>Flexion - Position neutre - Extension:</label>
          <input type="number" className="number-input" name="kneeFlexion" min="0" max="150" placeholder="135" value={formState.kneeFlexion} onChange={handleInputChange} />
          <input type="number" className="number-input" name="kneeNeutral" min="0" max="150" placeholder="0" value={formState.kneeNeutral} onChange={handleInputChange} />
          <input type="number" className="number-input" name="kneeExtension" min="-30" max="150" placeholder="5" value={formState.kneeExtension} onChange={handleInputChange} />
        </div>


      
  
          <select name="kneeMedialStability" className="select-input" value={formState.kneeMedialStability} onChange={handleInputChange} >
            <option value="">Stabilité compartiment interne:</option>
            <option value="Stable">Stable</option>
            <option value="Instable à 30, stable en extension">Instable à 30, stable en extension</option>
            <option value="Instable à 30, instable en extension">Instable à 30, instable en extension</option>
            <option value="Non testable">Non testable</option>
          </select>
       
        
          <select name="kneeLateralStability" className="select-input" value={formState.kneeLateralStability} onChange={handleInputChange}>
            <option value="">Stabilité compartiment externe:</option>
            <option value="Stable">Stable</option>
            <option value="Instable à 30, stable en extension">Instable à 30, stable en extension</option>
            <option value="Instable à 30, instable en extension">Instable à 30, instable en extension</option>
            <option value="Non testable">Non testable</option>
          </select>
       
          <select name="kneeLachman" className="select-input" value={formState.kneeLachman} onChange={handleInputChange}>
            <option value="">Lachman:</option>
            <option value="Physiologique, arrêt net">Physiologique, arrêt net</option>
            <option value="Pathologique, trajet prolongé / sans arrêt net">Pathologique, trajet prolongé / sans arrêt net</option>
            <option value="Non testable">Non testable</option>
          </select>
        
          <select name="kneeAnteriorDrawer" className="select-input" value={formState.kneeAnteriorDrawer} onChange={handleInputChange}>
          <option value="">Tiroir antérieur:</option>
            <option value="Physiologique, arrêt net">Physiologique, arrêt net</option>
            <option value="Pathologique, trajet prolongé / sans arrêt net">Pathologique, trajet prolongé / sans arrêt net</option>
            <option value="Non testable">Non testable</option>
          </select>
       
          <select name="kneePosteriorDrawer" className="select-input" value={formState.kneePosteriorDrawer} onChange={handleInputChange} >
          <option value="">Tiroir postérieur:</option>
            <option value="Physiologique, arrêt net">Physiologique, arrêt net</option>
            <option value="Pathologique, trajet prolongé / sans arrêt net">Pathologique, trajet prolongé / sans arrêt net</option>
            <option value="Non testable">Non testable</option>
          </select>
        
        
          <select name="kneePivotShift" className="select-input" value={formState.kneePivotShift} onChange={handleInputChange}>
          <option value="">Pivot Shift:</option>
            <option value="Physiologique, sans ressaut">Physiologique, sans ressaut</option>
            <option value="Pathologique, avec ressaut ressenti">Pathologique, avec ressaut ressenti</option>
            <option value="Non testable">Non testable</option>
          </select>
       


        <select name="McMurrayTest" className="select-input" value={formState.McMurrayTest} onChange={handleInputChange}>
          <option value="">McMurray:</option>
            <option value="négatif">négatif</option>
            <option value="positif en interne">positif en interne</option>
            <option value="positif en externe">positif en externe</option>
            <option value="Non testable">Non testable</option>
            <option value="AUTRE">AUTRE</option>
          </select>


          <select name="GrindingTest" className="select-input" value={formState.GrindingTest} onChange={handleInputChange}>
          <option value="">GrindingTest:</option>
            <option value="négatif">négatif</option>
            <option value="positif en interne">positif en interne</option>
            <option value="positif en externe">positif en externe</option>
            <option value="Non testable">Non testable</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="PatellarTracking" className="select-input" value={formState.PatellarTracking} onChange={handleInputChange}>
          <option value="">Tracking rotulien:</option>
            <option value="harmonieux">harmonieux</option>
            <option value="J-sign">J-sign</option>
            <option value="AUTRE">AUTRE</option>
          </select>

          <select name="RotationStability" className="select-input" value={formState.RotationStability} onChange={handleInputChange}>
          <option value="">Dial test:</option>
            <option value="rotation similaire au controlatéral, à 30° et à 90°">rotation similaire au controlatéral, à 30° et à 90°</option>
            <option value="rotation augmentée par rapport au controlatéral à 30°, similaire à 90°">rotation augmentée par rapport au controlatéral à 30°, similaire à 90°</option>
            <option value="rotation augmentée par rapport au controlatéral à 30° et à 90°">rotation augmentée par rapport au controlatéral à 30° et à 90°</option>
            <option value="AUTRE">AUTRE</option>
          </select>



        </div>


        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default Knee;