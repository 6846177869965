import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";


function Imaging_Lipo({setNameForm, onFormSubmit, onSelectionChange}) {




  const [formState, setFormState] = useState({
    Location: "INFORMATION MANQUANTE",
    Size: "INFORMATION MANQUANTE",
    LesionType:"INFORMATION MANQUANTE",
    Pain:"INFORMATION MANQUANTE",
    
  });



  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);



  const [MirelsCalculation, setMirelsCalculation] = useState(null);

  useEffect(() => {
    if (formState.Location && formState.Size &&
      formState.LesionType && formState.Pain 

    ) {
      
      var MirelsCalculation = 0;
     
      if (formState.Location === "membre supérieur") {MirelsCalculation += 1;}
      if (formState.Location === "membre inférieur") {MirelsCalculation += 2;}
      if (formState.Location === "région trochantérienne") {MirelsCalculation += 3;}

      if (formState.Size === "<1/3") {MirelsCalculation += 1;}
      if (formState.Size === "entre 1/3 et 2/3") {MirelsCalculation += 2;}
      if (formState.Size === ">2/3") {MirelsCalculation += 3;}

      if (formState.LesionType === "blastique") {MirelsCalculation += 1;}
      if (formState.LesionType === "mixte") {MirelsCalculation += 2;}
      if (formState.LesionType === "lytique") {MirelsCalculation += 3;}

      if (formState.Pain === "absentes ou légères") {MirelsCalculation += 1;}
      if (formState.Pain === "modérée") {MirelsCalculation += 2;}
      if (formState.Pain === "fonctionnelles") {MirelsCalculation += 3;}

 
      setMirelsCalculation(MirelsCalculation);
     } 

     
     else {
       setMirelsCalculation("");
     }
 }, [
  formState.Location, formState.Size,
  formState.LesionType, formState.Pain]);






  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Score de Mirels");
 

    // Prepare form details for summary
    const formDetails = {

      'Localisation': formState.Location,
      'Taille (ratio sur épaisseur cortical)': formState.Size,
      'Type lésion': formState.LesionType,
      'Douleurs': formState.Pain,
  
      'Score de Mirels calculé': MirelsCalculation  
      
      
    };


    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };





  return (
    <div className="card">
      <p className="title">Score de Mirels</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div>     
        <select name="Location" className="select-input" value={formState.Location} onChange={handleInputChange} >
          <option value="">Localisation:</option>
          <option value="membre supérieur">Membre supérieur</option>
          <option value="membre inférieur">Membre inférieur</option>
          <option value="région trochantérienne">région trochantérienne</option>
        </select>
      </div>

      <div>     
        <select name="Size" className="select-input" value={formState.Size} onChange={handleInputChange} >
          <option value="">Taille lésion, ration lésion / épaisseur corticale:</option>
          <option value="<1/3">{'<'} 1/3</option>
          <option value="entre 1/3 et 2/3">{'>'} 1/3, {'<'} 2/3</option>
          <option value=">2/3">{'>'} 2/3</option>
        </select>
      </div>


      <div>     
        <select name="LesionType" className="select-input" value={formState.LesionType} onChange={handleInputChange} >
          <option value="">Type de lésion:</option>
          <option value="blastique">blastique</option>
          <option value="mixte">mixte</option>
          <option value="lytique">lytique</option>
        </select>
      </div>

      <div>     
        <select name="Pain" className="select-input" value={formState.Pain} onChange={handleInputChange} >
          <option value="">Douleurs:</option>
          <option value="absentes ou légères">absentes ou légères</option>
          <option value="modérée">modérée</option>
          <option value="fonctionnelles">fonctionnelles</option>
        </select>
      </div>
    

 

          
            
          
      
        
        <button type="submit" className="button send">Send</button>
      </form>


      <br></br>

     
     


    </div>
  );
}

export default Imaging_Lipo;