import React, { useState, useEffect } from "react";
import "../../../../styles/app.css";

function PelvisXRayTraumat({setNameForm, onFormSubmit, onSelectionChange}) {
  const [formState, setFormState] = useState({
    xRayWorkUp: '',
    sidePelvis: '',
    pelvicFracture: '',
    acetabularFracture: '',
    showXrayWorkup:''
  });


  const [showXray, setshowXray] = useState(false);

  const handleChangeshowXray = (event) => {
    setshowXray(event.target.checked);
  }

  const [pelvisXrayWorkupTraumat, setpelvisXrayWorkupTraumat] = useState({
    "Bassin de face":false,
    "Bassin calibré centré symphyse":false,
    "Obturatrice gauche":false,
    "Alaire gauche": false,
    "Obturatrice droite": false,
    "Alaire droite": false,
    "Inlet": false,
    "Outlet": false,
    "CT-scan": false,
  });

  useEffect(() => {
    // Run this when the component mounts
    onSelectionChange(true);

    // Run this when the component unmounts
    return () => {
      onSelectionChange(false);
    };
  }, []);


  const handleCheckboxChangepelvisXrayWorkupTraumat = (event) => {
    const { name, checked } = event.target;
     
      setpelvisXrayWorkupTraumat(prev => ({
        ...prev,
        [name]: checked,
     
      }));
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setNameForm("Physical exam [Knee]");
 // Function to convert the state of the checkboxes into a readable string
    const formatCheckboxStateToString = (checkboxState) => {
      const selectedOptions = Object.entries(checkboxState)
          .filter(([_, isChecked]) => isChecked)
          .map(([key, _]) => key) // Get only the keys (option names)
          .join(", ");

      return selectedOptions || ""; // If no options are selected, return "None selected"
  };

  const pelvisXrayWorkupTraumatText = formatCheckboxStateToString(pelvisXrayWorkupTraumat);


    // Prepare form details for summary
    const formDetails = {
      "Bilan radiologique": pelvisXrayWorkupTraumatText,
      "Du côté": formState.sidePelvis,
      "Fracture anneau pelvien de type": formState.pelvicFracture,
      "Fracture acétabulum": formState.acetabularFracture,
      
    };

    const filteredFormDetails = Object.entries(formDetails) // Convert the object to an array of key-value pairs
    .filter(([key, value]) => value !== "")               // Filter out pairs where the value is an empty string
    .reduce((obj, [key, value]) => {                      // Reduce the array back to an object
      obj[key] = value;                                   // Add each key-value pair to the object
      return obj;                                         // Return the object for the next iteration
    }, {});                                               // Start with an empty object

   


    onFormSubmit(filteredFormDetails); // Call the function passed from App.js and pass the details
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  return (
    <div className="card">
      <p className="title">Pelvis, bilan radiologique</p>
      <form className="specific-form" onSubmit={handleSubmit}>


      <div>
   
      <input
          type="checkbox"
          name="showXray"
          onChange={handleChangeshowXray}
          
        />
        <label>Renseigner incidences radiologiques</label>

      </div>
      

      {showXray && (
                   <div className="checkbox-options">
        
                   {Object.entries(pelvisXrayWorkupTraumat).map(([key, value]) => (
                     <div key={key}>
                       <input type="checkbox" name={key} checked={value} onChange={handleCheckboxChangepelvisXrayWorkupTraumat} />
                       <label>{key}</label>
                     </div>
                   ))}
                 </div>
      )
    }
       

      <br></br>


        <div className="checkbox-options">
          <p>Côté lésion:</p>
            <div>
             <input type="radio" name="sidePelvis" value="gauche" checked={formState.sidePelvis === "gauche"} onChange={handleInputChange} />
            <label> gauche </label>
         
            <input type="radio" name="sidePelvis" value="droit" checked={formState.sidePelvis === "droit"} onChange={handleInputChange} />
            <label> droit </label>
            </div>
        </div>


        <div>
          <label>Fracture du bassin</label>
          <select name="pelvicFracture" className="select-input" value={formState.pelvicFracture} onChange={handleInputChange} >
            <option value="">Select an option</option>
            <option value="LC I: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, fracture aileron sacré">LC I: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, fracture aileron sacré</option>
            <option value="LC II: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, fracture Ilium (Crescent sign)">LC II: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, fracture Ilium (Crescent sign)</option>
            <option value="LC III: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, luxation sacro-iliaque">LC III: Compression latérale, avec fracures des branches ilio-pubienne et ischio-pubienne, luxation sacro-iliaque</option>
            <option value="AP I: Compression antéro-postérieure, diastase symphyse pubienne moins de 2,5cm">AP I: Compression antéro-postérieure, diastase symphyse pubienne moins de 2,5cm</option>
            <option value="AP II: Compression antéro-postérieure, diastase symphyse pubienne plus de 2,5cm (diastase sacro-iliaque antérieur, pas d'atteinte SI postérieure)">AP II: Compression antéro-postérieure, diastase symphyse pubienne plus de 2,5cm (diastase sacro-iliaque antérieur, pas d'atteinte SI postérieure)</option>
            <option value="AP III: Compression antéro-postérieure, avec compression luxation sacro-iliaque">AP III: Compression antéro-postérieure, avec compression luxation sacro-iliaque</option>
            <option value="Vertical shear">Vertical shear</option>
          </select>
        </div>


        <div>
          <label>Fracture acétabulum</label>
          <select name="acetabularFracture" className="select-input" value={formState.acetabularFracture} onChange={handleInputChange} >
            <option value="">Select an option</option>
            <option value="mur postérieur">mur postérieur</option>
            <option value="colonne postérieure">colonne postérieure</option>
            <option value="colonne antérieure">colonne antérieure</option>
            <option value="fracture transverse">fracture transverse</option>
            <option value="colonne postérieure et mur postérieur">colonne postérieure et mur postérieur</option>
            <option value="T-shaped">T-shaped</option>
            <option value="colonne antérieure et hémi-transverse postérieure">colonne antérieure et hémi-transverse postérieure</option>
            <option value="fractures colonnes antérieure et postérieure">fractures colonnes antérieure et postérieure</option>

          </select>
        </div>
        
        <button type="submit" className="button send">Send</button>
      </form>
    </div>
  );
}

export default PelvisXRayTraumat;