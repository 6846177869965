import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();
const AuthContext = createContext();

export const UserProvider = ({ children }) => {
    const [cookieConsent, setCookieConsent] = useState(null);
    const [isVisibleCookieBanner, setIsVisibleCookieBanner] = useState(false);
    const [isGAInitialized, setIsGAInitialized] = useState(false);

    // Function to check if the cookie consent has been given
    const checkCookieConsent = () => {
        const storedConsent = localStorage.getItem('cookieConsent');
        setCookieConsent(storedConsent === 'true'); // Update state based on storage
        if (storedConsent === null) {
            setIsVisibleCookieBanner(true); // Show banner if no consent
        }
    };

    // Function to explicitly show the cookie consent banner
    const showCookieBanner = () => {
        setIsVisibleCookieBanner(true); // Explicitly show banner
    };

    // Initialize the consent state on load
    useEffect(() => {
        checkCookieConsent(); // Check consent on initial load
    }, []);

    return (
        <AuthContext.Provider value={{cookieConsent, setCookieConsent, isVisibleCookieBanner, setIsVisibleCookieBanner, showCookieBanner, checkCookieConsent}} >
        <UserContext.Provider value={{isGAInitialized, setIsGAInitialized}}>
            {children}
        </UserContext.Provider>
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
export const useUser = () => useContext(UserContext);