import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";
import "../styles/initialCard.css";

const MainHome = () => {
    return (
      <div className="home-container">
        <h1 className="home-title">Dr Click</h1>
        <p className="home-intro">
        Text intro
        </p>
        <div className="home-cards-grid">
          <div className="home-card initial">
            <Link to="/orthopedics">
              <img className="initial-image" src="/orthopedics.webp" alt="Orthopedics" />
              <p className="home-card-title">Orthopédie et traumatologie</p>
            </Link>
          </div>
          <div className="home-card initial">
            <Link to="/Orthopedic_Oncology">
              <img className="initial-image" src="/Orthopaedic_Oncology.webp" alt="Orthopaedic Oncology" />
              <p className="home-card-title">Orthopédie oncologique</p>
            </Link>
          </div>
          <div className="home-card initial">
            <Link to="/cardiology">
              <img className="initial-image" src="/Scalpel.webp" alt="Knife" />
              <p className="home-card-title">Compte-rendu opératoire: en construction</p>
            </Link>
          </div>
        </div>
      </div>
    );
};

export default MainHome;